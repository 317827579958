import logo from "../../assets/bplogofull.png";
import "./Login.module.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Alert } from "reactstrap";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const Login = (props) => {
  localStorage.removeItem("sessionresultDest");
  localStorage.removeItem("sessionresultStatus");
  localStorage.removeItem("sessionHistory");
  localStorage.removeItem("sessionStatusvalue");
  localStorage.removeItem("sessionCustomHistory");
  localStorage.removeItem("sessionOrderPagePrevKey");
  localStorage.removeItem("localStorage.removeItem");
  localStorage.removeItem("localStorage.sessionfilterurl");
  const [url, setUrl] = useState();
  const [devbpcurl, setdevBpcUrl] = useState(process.env.REACT_APP_DEV_BPC_URL);
  const [qabpcurl, setQaBpcUrl] = useState(process.env.REACT_APP_QA_BPC_URL);
  const [prodbpcurl, setprodBpcUrl] = useState(
    process.env.REACT_APP_PROD_BPC_URL
  );
  const search = useLocation().search;
  const emessage = new URLSearchParams(search).get("error");
  const [selectddlval, setselectddlval] = useState("");
  const [isShow, setIsShow] = React.useState(false);

  useEffect(() => {
    var tabUrl = document.URL;
    var parts = tabUrl.split("/");
    setUrl(parts[parts.length - 2]);
  }, []);

  function checkresponse() {
    const message = [];
    if (emessage !== undefined && emessage !== "" && emessage !== null) {
      message.push(<Alert color="danger">{emessage}</Alert>);
    }
    return message;
  }
  function redirectToBPC() {
    if (url === "tlv.bp.com") {
      window.location = prodbpcurl;
    } else if (url === "tlvqa.bp.com") {
      window.location = qabpcurl;
    } else {
      window.location = devbpcurl;
    }
  }
  const handleChange = (event) => {
    setselectddlval(event.target.value);
    setIsShow(true);
  };

  const Loginbyddlselection = () =>{
    console.log(selectddlval)
    if(selectddlval==="BPC")
    {
        redirectToBPC();
    }
    else if(selectddlval==="PDI")
    {
      navigate("/PdiLogin");
    }
    else if(selectddlval==="SAML")
    {
      console.log("SAML selected");
    }
  }

  const navigate = useNavigate();
  return (
    <div className="App">
        {checkresponse()}
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <span className="Truck-Load-Visibilit">Truck Load Visibility</span>
        <span className="You-are-not-signed-i"> You are not signed in.</span>
         <button
          type="button"
          onClick={() => redirectToBPC()}
          className="btn btn-link Sign-in-via-bp-connection"
        >
          Sign in via bp connection{" >"}
        </button>
        <label className="lblchangecolor">or</label>
        <button
          type="button"
          onClick={() =>  navigate("/PdiLogin")}
          className="btn btn-link Sign-in-via-bp-connection"
        >
          Sign in as pdi users{" >"}
        </button>
       {/* <Box sx={{ minWidth: 205 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select sign in
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectddlval}
              label="URL"
              onChange={handleChange}
            >
              <MenuItem value={"BPC"}>Sign in via bp connection</MenuItem>
              <MenuItem value={"PDI"}>Sign in as pdi users</MenuItem>
              <MenuItem value={"ROSIplus"}>Sign in via ROSIplus</MenuItem>
              <MenuItem value={"Dealer Portal"}>Sign in via Dealer Portal</MenuItem>
            </Select>
          </FormControl>
        </Box> 
        {isShow && <button type="button" onClick={() => Loginbyddlselection()} className="btn btn-success lblsetmargin">GO</button> }*/}
        
      </header>
    </div>
  );
};

export default Login;
