import React, {useState, useEffect} from "react";
import headerLogo from "../../assets/bplogofull.png";
import axios from "axios";
import LogoutImg from "../../assets/shutdown.png";

const Header = (props) => {
  const [url, setUrl] = useState(process.env.REACT_APP_URL);
  const [currentuserid, setCurrentuserId] = useState(null);
      useEffect(() => {
        axios
        .get(url +"/api/SessionInfo")
         .then(function (res) {
          setCurrentuserId(res.data.user_id);
         })
         .catch(function (err) {
           if (err.response.status === 401) {
             window.location = "/Login?error="+err.response.data.message;
           }
            else if (err.response.status === 403) {
             window.location = "/Login?error="+"Your token is expired, please login from bpc.";
           } else {
             window.location = "/Error?error="+err.message;
           }
         });
      }, []);

      const logout = (e) => {
        localStorage.removeItem("sessionresultDest");
        localStorage.removeItem("sessionresultStatus");
        localStorage.removeItem("sessionHistory");
        localStorage.removeItem("sessionStatusvalue");
        localStorage.removeItem("sessionCustomHistory");
        localStorage.removeItem("sessionuserId");
        localStorage.removeItem("sessionOrderPageKey");
        localStorage.removeItem("sessionOrderPagePrevKey");
        localStorage.removeItem("sessionapplyfiltercount");
        localStorage.removeItem("sessioncheckedSettings");
        localStorage.removeItem("sessiontxtsearchval");
        localStorage.removeItem("sessioncurrenttlvUrl");
        e.preventDefault();
        axios
          .get(url+ "/auth/Logout")
          .then(function (res) {
            window.location = "/Login";
          })
          .catch(function (err) {
            if (err.response.status === 401) {
              window.location = "/Login?error=" + err.response.data.message;
            } else if (err.response.status === 403) {
              window.location =
                "/Login?error=" + "Your token is expired, please login from bpc.";
            } else {
              window.location = "/Error?error=" + err.message;
            }
          });
      };
       
  return (
    <header className="background">
      <nav class="navbar navbar-light bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href={url}>
            <img src={headerLogo} className="App-headerlogo" alt="logo" />
            <span className="txtcolor">Truck Load Visibility</span>
          </a>
          <label className="lbluserId">Logged in as : {currentuserid}<button className="btn btn-success btn-sm desktop disblock" onClick={logout}>Logout</button></label>
          <img src={LogoutImg}  className="App-Logoutlogo mobile" alt="logout" title="Logout" onClick={logout} />
        </div>
      </nav>
    </header>
  );
};

export default Header;
