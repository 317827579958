import React, { useState, useEffect } from "react";
import ".././Orders/Modal.css";
import axios from "axios";

const ModalColumn = (props) => {
  const [disabled, setdisabled] = useState(true);
  const [userSettingData, setuserSettingData] = React.useState([]);
  const [url, setUrl] = useState(process.env.REACT_APP_URL);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios
      .get(url + "/api/getusersettings")
      .then(function (res) {
        setuserSettingData(res.data);
      })
      .catch(function (err) {
        if (err.response.status === 401) {
          window.location = "/Login?error=" + err.response.data.message;
        } else if (err.response.status === 403) {
          window.location =
            "/Login?error=" + "Your token is expired, please login from bpc.";
        } else {
          window.location = "/Error?error=" + err.message;
        }
      });
  }, []);

  useEffect(() => {
   try{
 var getSettingsvalue;
     getSettingsvalue =localStorage.getItem("sessioncheckedSettings");
    if(getSettingsvalue === null || getSettingsvalue === "" || getSettingsvalue === undefined)
    {
     getSettingsvalue = userSettingData.resultSettings;
    }
    if (
      getSettingsvalue != null &&
      getSettingsvalue != "" && getSettingsvalue != undefined
    ) {
      var getsettingval = getSettingsvalue.split(",");
      getsettingval.pop();
      if (
        getsettingval != "" &&
        getsettingval != "" &&
        getsettingval.length > 0
      ) {
        for (var i = 0; i < getsettingval.length; i++) {
          if(props.isWestcoast === "true")
          {
            if(getsettingval[0] == "true")
            {
             var destinputs = document.getElementById("Destination_");
             if(destinputs != null)
             {
              destinputs.checked = true;
              setdisabled(false);
             }
              
            }
            if(getsettingval[1] == "true")
            {
             var SiteInput = document.getElementById("Site Number_");
             if(SiteInput != null)
             {
                 SiteInput.checked = true;
                 setdisabled(false);
             }
            }
            if(getsettingval[2] == "true")
            {
              var EtaInput = document.getElementById("ETA_");
              if(EtaInput != null)
             {
                  EtaInput.checked = true;
                  setdisabled(false);
             }
            }
            if(getsettingval[3] == "true")
            {
              var CarrierInput = document.getElementById("Carrier_");
              if(CarrierInput != null)
             {
              CarrierInput.checked = true;
              setdisabled(false);
             }
            }
            if(getsettingval[4] == "true")
            {
              var SiteNameInput = document.getElementById("Site Name_");
              if(SiteNameInput != null)
              {
              SiteNameInput.checked = true;
              setdisabled(false);
              }
            }
            if(getsettingval[5] == "true")
            {
              var ArcoNumInput = document.getElementById("Arco Number_");
              if(ArcoNumInput != null)
              {
              ArcoNumInput.checked = true;
              setdisabled(false);
              }
            }
            if(getsettingval[6] == "true")
            {
              var OrderInput = document.getElementById("Order_");
              if(OrderInput != null)
              {
              OrderInput.checked = true;
              setdisabled(false);
              }
            }
            if(getsettingval[7] == "true")
            {
              var StatusInput = document.getElementById("Status_");
              if(StatusInput != null)
              {
              StatusInput.checked = true;
              setdisabled(false);
              }
            }
            if(getsettingval[8] == "true")
            {
              var UpdatedInput = document.getElementById("Updated_");
              if(UpdatedInput != null)
              {
              UpdatedInput.checked = true;
              setdisabled(false);
              }
            }
          }
          else
          {
            if(getsettingval[0] == "true")
            {
             var destinputs = document.getElementById("Destination_");
             if(destinputs != null)
             {
              destinputs.checked = true;
              setdisabled(false);
             }
              
            }
            if(getsettingval[1] == "true")
            {
              var EtaInput = document.getElementById("ETA_");
              if(EtaInput != null)
             {
                  EtaInput.checked = true;
                  setdisabled(false);
             }
            }
            if(getsettingval[2] == "true")
            {
              var CarrierInput = document.getElementById("Carrier_");
              if(CarrierInput != null)
             {
              CarrierInput.checked = true;
              setdisabled(false);
             }
            }
            if(getsettingval[3] == "true")
            {
              var SiteNameInput = document.getElementById("Site Name_");
              if(SiteNameInput != null)
              {
              SiteNameInput.checked = true;
              setdisabled(false);
              }
            }
            if(getsettingval[4] == "true")
            {
              var OrderInput = document.getElementById("Order_");
              if(OrderInput != null)
              {
              OrderInput.checked = true;
              setdisabled(false);
              }
            }
            if(getsettingval[5] == "true")
            {
              var StatusInput = document.getElementById("Status_");
              if(StatusInput != null)
              {
              StatusInput.checked = true;
              setdisabled(false);
              }
            }
            if(getsettingval[6] == "true")
            {
              var UpdatedInput = document.getElementById("Updated_");
              if(UpdatedInput != null)
              {
              UpdatedInput.checked = true;
              setdisabled(false);
              }
            }
          }
         
        }
      }
    }
   }
   catch (err) {
    //console.log(err.message);
  } finally {
    setTimeout(function () {
      setIsLoading(false);
    }, 500);
  }
  });

  const ApplyFiltersColumn = () => {
    localStorage.removeItem("sessioncheckedSettings");
    //var checkedSettings = document.getElementsByClassName("chkSettings").checked;
    var Destchecked = document.getElementById("Destination_").checked;
    var ETAchecked = document.getElementById("ETA_").checked;
    var Carrierchecked = document.getElementById("Carrier_").checked;
    var SiteNamechecked = document.getElementById("Site Name_").checked;
    var Orderchecked = document.getElementById("Order_").checked;
    var Statuschecked = document.getElementById("Status_").checked;
    var Updatedchecked = document.getElementById("Updated_").checked;
    if(props.isWestcoast === "true")
    {
      var SiteIdchecked = document.getElementById("Site Number_").checked;
      var ArcoNumberchecked = document.getElementById("Arco Number_").checked;
    }
    var resultSettings = [];
    if(props.isWestcoast === "true")
    {
      resultSettings += Destchecked +","+ SiteIdchecked + "," + ETAchecked + "," + Carrierchecked + "," +  SiteNamechecked + "," + ArcoNumberchecked + "," + Orderchecked + "," + Statuschecked + "," + Updatedchecked + "," ;
    }
    else{
      resultSettings += Destchecked +"," + ETAchecked + "," + Carrierchecked + "," +  SiteNamechecked + "," + Orderchecked + "," + Statuschecked + "," + Updatedchecked + "," ;
    }
        
    // for (var i = 0; i < checkedSettings.length; i++) {
    //   if (checkedSettings[i].checked) {
    //     resultSettings += checkedSettings[i].value + ",";
    //   }
    // }
    if (resultSettings.length == 0) {
      alert("please select atleast one checkbox.");
      return false;
    } else {
      localStorage.setItem("sessioncheckedSettings", resultSettings);
      setIsLoading(true);
      axios
        .post(url + "/api/setusersettings", { resultSettings })
        .then(function (res) {
         // alert(res.data);
          props.onClose();
        })
        .catch(
          function (err) {
            if (err.response.status === 401) {
              window.location = "/Login?error=" + err.response.data.message;
            } else if (err.response.status === 403) {
              window.location =
                "/Login?error=" +
                "Your token is expired, please login from bpc.";
            } else {
              window.location = "/Error?error=" + err.message;
            }
          }
        ).finally(function () {
          setTimeout(function () {
            setIsLoading(false);
          }, 1000);
        });
    }
  };
  const modalClose = () => {
    props.onClose();
  };

  if (!props.ColumnFiltershow) {
    return null;
  }
  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Site Settings</h4>
          <button
            type="button"
            className="btn-close"
            onClick={modalClose}
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <div className="row rowmodal">
            <div className="col-10">
              <label className="custom-control-label" htmlFor={"Destination_"}>
                Destination
              </label>
            </div>
            <div className="col">
              <input
                type="checkbox"
                className="custom-control-input chkSettings"
                id={"Destination_"}
                value={"Destination"}
                onClick={() => {
                  setdisabled(false);
                }}
              />
            </div>
          </div>
          {props.isWestcoast === "true" ? (
            <div className="row rowmodal">
              <div className="col-10">
                <label
                  className="custom-control-label"
                  htmlFor={"Site Number_"}
                >
                  Site Number
                </label>
              </div>
              <div className="col">
                <input
                  type="checkbox"
                  className="custom-control-input chkSettings"
                  id={"Site Number_"}
                  value={"Site Number"}
                  onClick={() => {
                    setdisabled(false);
                  }}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="row rowmodal">
            <div className="col-10">
              <label className="custom-control-label" htmlFor={"ETA_"}>
                ETA
              </label>
            </div>
            <div className="col">
              <input
                type="checkbox"
                className="custom-control-input chkSettings"
                id={"ETA_"}
                value={"ETA"}
                onClick={() => {
                  setdisabled(false);
                }}
              />
            </div>
          </div>
          <div className="row rowmodal">
            <div className="col-10">
              <label className="custom-control-label" htmlFor={"Carrier_"}>
                Carrier
              </label>
            </div>
            <div className="col">
              <input
                type="checkbox"
                className="custom-control-input chkSettings"
                id={"Carrier_"}
                value={"Carrier"}
                onClick={() => {
                  setdisabled(false);
                }}
              />
            </div>
          </div>
          <div className="row rowmodal">
            <div className="col-10">
              <label className="custom-control-label" htmlFor={"Site Name_"}>
                Site Name
              </label>
            </div>
            <div className="col">
              <input
                type="checkbox"
                className="custom-control-input chkSettings"
                id={"Site Name_"}
                value={"Site Name"}
                onClick={() => {
                  setdisabled(false);
                }}
              />
            </div>
          </div>
          {props.isWestcoast === "true" ? (<div className="row rowmodal">
            <div className="col-10">
              <label className="custom-control-label" htmlFor={"Arco Number_"}>
              Arco Number
              </label>
            </div>
            <div className="col">
              <input
                type="checkbox"
                className="custom-control-input chkSettings"
                id={"Arco Number_"}
                value={"Arco Number"}
                onClick={() => {
                  setdisabled(false);
                }}
              />
            </div>
          </div>):("")}
          
          <div className="row rowmodal">
            <div className="col-10">
              <label className="custom-control-label" htmlFor={"Order_"}>
                Order
              </label>
            </div>
            <div className="col">
              <input
                type="checkbox"
                className="custom-control-input chkSettings"
                id={"Order_"}
                value={"Order"}
                onClick={() => {
                  setdisabled(false);
                }}
              />
            </div>
          </div>
          <div className="row rowmodal">
            <div className="col-10">
              <label className="custom-control-label" htmlFor={"Status_"}>
                Status
              </label>
            </div>
            <div className="col">
              <input
                type="checkbox"
                className="custom-control-input chkSettings"
                id={"Status_"}
                value={"Status"}
                onClick={() => {
                  setdisabled(false);
                }}
              />
            </div>
          </div>
          <div className="row rowmodal">
            <div className="col-10">
              <label className="custom-control-label" htmlFor={"Updated_"}>
                Updated
              </label>
            </div>
            <div className="col">
              <input
                type="checkbox"
                className="custom-control-input chkSettings"
                id={"Updated_"}
                value={"Updated"}
                onClick={() => {
                  setdisabled(false);
                }}
              />
            </div>
          </div>

          <div className="modal-footer">
            {/* <button
            onClick={() => {
              ClearAll();
              setdisabled(true);
            }}
            className="btn btn-outline-success btn-sm"
          >
            Clear All
          </button> */}
            <button
              id="btnApplyFilter"
              onClick={() => {
                ApplyFiltersColumn();
              }}
              disabled={disabled}
              className="btn btn-outline-success btn-sm"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalColumn;
