import React, { useState,useEffect } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import OrderTable from "./OrderTable";
import "../../App.css";
import axios from "axios";
import { Spinner } from 'reactstrap';

const Order = (props) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [url, setUrl] = useState(process.env.REACT_APP_URL);
  useEffect(() => {
     axios
     .get(url +"/api/SessionInfo")
      .then(function (res) {
         setCurrentUser(res);
      })
      .catch(function (err) {
        localStorage.removeItem("sessiontxtsearchval");
        localStorage.removeItem("sessionfilterurl");
        if (err.response.status === 401) {
          window.location = "/Login?error="+err.response.data.message;
        }
         else if (err.response.status === 403) {
          window.location = "/Login?error="+"Your token is expired, please login from bpc.";
        } else {
          window.location = "/Error?error="+err.message;
        }
      })
  }, []);
  return (
    <div className="fixed-header">
      {currentUser ? (
        <div className="divOrder">
          <Header></Header>
          <OrderTable />
          <Footer />
        </div>
      ) : (
        <div className="loader">
                       <Spinner color="white" />
                   </div>
      )}
    </div>
  );
};

export default Order;
