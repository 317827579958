import React, { useRef, useEffect, useState } from "react";
import Map from "@arcgis/core/WebMap";
import MapView from "@arcgis/core/views/MapView";
import Graphic from "@arcgis/core/Graphic";
import Stop from "@arcgis/core/rest/support/Stop";
import Point from "@arcgis/core/geometry/Point";
import "./map.css";
import TankImage from "../../assets/tanktruck.png";
import DestImage from "../../assets/location.png";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import * as route from "@arcgis/core/rest/route";
import RouteParameters from "@arcgis/core/rest/support/RouteParameters";
import FeatureSet from "@arcgis/core/rest/support/FeatureSet";
import axios from "axios";
import $ from 'jquery';

const EsriMap = (props) => {
  const mapRef = useRef(null);
  const [mapData, setMapData] = useState([]);
  const [url, setUrl] = useState(process.env.REACT_APP_URL);
  useEffect(() => {
    if (props.OrderId != undefined && props.SiteId != undefined) {
      axios
        .get(
          url +"/api/orders/" +
            props.OrderId +
            "/" +
            props.SiteId
        )
        .then(function (res) {
          setMapData(res.data.Map);
          if (res.data.Map.Latitude != "0" && res.data.Map.Longitude != "0") {
            setInterval(() => renderMap(), 540000);
          }
        })
        .catch(function (err) {
          if (err.response.status === 401) {
            window.location = "/Login?error=" + err.response.data.message;
          } else if (err.response.status === 403) {
            window.location =
              "/Login?error=" + "Your token is expired, please login from bpc.";
          } else {
            window.location = "/Error?error=" + err.message;
          }
        });
    }
  }, []);

  function callforMap() {
    if (mapData != undefined && mapData != null) {
      var TruckLatitude = parseFloat(mapData.Latitude);
      var TruckLongitude = parseFloat(mapData.Longitude);
      var OriginLatitude = parseFloat(mapData.OriginLatitude);
      var OriginLongitude = parseFloat(mapData.OriginLongitude);
      var DestLatitude = parseFloat(mapData.SiteLatitude);
      var DestLongitude = parseFloat(mapData.SiteLongitude);

      var mapCentreLatitude;
      var mapCentreLongitude;
      if (!isNaN(TruckLatitude) && !isNaN(TruckLongitude) && TruckLongitude != 0 && TruckLatitude != 0) 
      {
        mapCentreLatitude = TruckLatitude;
        mapCentreLongitude = TruckLongitude;
      } else if (!isNaN(OriginLatitude) && !isNaN(OriginLongitude)) {
        mapCentreLatitude = OriginLatitude;
        mapCentreLongitude = OriginLongitude;
      } else if (!isNaN(DestLatitude) && !isNaN(DestLongitude)) {
        mapCentreLatitude = DestLatitude;
        mapCentreLongitude = DestLongitude;
      } else {
        mapCentreLatitude = parseFloat(0);
        mapCentreLongitude = parseFloat(0);
      }

      const routeUrl =
        "https://route-api.arcgis.com/arcgis/rest/services/World/Route/NAServer/Route_World";

      // The stops and route result will be stored in this layer
      const routeLayer = new GraphicsLayer();

      // Setup the route parameters
      const routeParams = new RouteParameters({
        // An authorization string used to access the routing service 
       apiKey:process.env.REACT_APP_MAP_SECRET_KEY,
        stops: new FeatureSet(),
        outSpatialReference: {
          // autocasts as new SpatialReference()
          wkid: 3857,
        },
      });

      const OriginSymbol = {
        type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
        color: "Lime",
        outline: {
          color: [128, 128, 128, 0.5],
          width: "0.5px",
        },
      };
      const routeSymbol = {
        type: "simple-line", // autocasts as SimpleLineSymbol()
        color: [0, 0, 255, 0.5],
        width: 5,
      };
      const TruckSymbol = {
        type: "picture-marker", // autocasts as new TextSymbol()
        text: "\ue69b",
        url: TankImage,
        height: "32px",
        width: "32px",
      };
      const DestinationSymbol = {
        type: "picture-marker", // autocasts as new TextSymbol()
        text: "\ue69b",
        url: DestImage,
        height: "40px",
        width: "40px",
      };

      const map = new Map({
        basemap: "streets-navigation-vector",
        //basemap: "streets-vector",
        layers: [routeLayer], // Add the route layer to the map
      });
      const view = new MapView({
        container: mapRef.current,
        map: map, // Reference to the map object created before the scene
        center: [mapCentreLongitude, mapCentreLatitude],
        zoom: 9,
      });
      if (!isNaN(OriginLatitude) && !isNaN(OriginLongitude) && OriginLatitude != "" && OriginLongitude != "") {
        const OriginLocation = new Point({
          longitude: OriginLongitude,
          latitude: OriginLatitude,
        });
        const pointGraphic = new Graphic({
          geometry: OriginLocation,
          symbol: OriginSymbol,
        });
        view.graphics.addMany([pointGraphic]);
      }

      if (!isNaN(DestLatitude) && !isNaN(DestLongitude) && DestLatitude != "" && DestLongitude != ""  && DestLatitude != 0 && DestLongitude != 0) 
      {
        const DestinationLocation = new Point({
          longitude: DestLongitude,
          latitude: DestLatitude,
        });
        const Destpoint = new Graphic({
          geometry: DestinationLocation,
          symbol: DestinationSymbol,
        });
        view.graphics.addMany([Destpoint]);
      }
      
      if (
        !isNaN(TruckLatitude) &&
        !isNaN(TruckLongitude) &&
        TruckLongitude != 0 &&
        TruckLatitude != 0
      ) {
        const TruckCurrentLocation = new Point({
          longitude: TruckLongitude,
          latitude: TruckLatitude,
        });
        const pointGraphic5 = new Graphic({
          geometry: TruckCurrentLocation,
          symbol: TruckSymbol,
        });
        view.graphics.addMany([pointGraphic5]);
      }
      if (!isNaN(TruckLatitude) && !isNaN(TruckLongitude) && !isNaN(OriginLatitude) && !isNaN(OriginLongitude) && TruckLatitude != 0 && TruckLongitude != 0) {
        const stops = [
          new Stop({
            geometry: { x: OriginLongitude, y: OriginLatitude },
          }),
          new Stop({
            geometry: { x: TruckLongitude, y: TruckLatitude },
          }),
        ];
        if (stops.length > 0) {
          debugger;
          for (let index = 0; index < stops.length; index++) {
            routeLayer.add(stops[index]);
            routeParams.stops.features.push(stops[index]);
            if (routeParams.stops.features.length >= 2) {
              route.solve(routeUrl, routeParams).then(showRoute);
            }
          }
        }
        function showRoute(data) {
          const routeResult = data.routeResults[0].route;
          routeResult.symbol = routeSymbol;
          routeLayer.add(routeResult);
        }
      }
    }
  }

  function renderMap() {
    if (props.OrderId != undefined && props.SiteId != undefined) {
      axios
        .get(
          url +"/api/orders/" +
            props.OrderId +
            "/" +
            props.SiteId
        )
        .then(function (res) {
          setMapData(res.data.Map);
          callforMap();
        })
        .catch(function (err) {
          if (err.response.status === 401) {
            window.location = "/Login?error=" + err.response.data.message;
          } else if (err.response.status === 403) {
            window.location =
              "/Login?error=" + "Your token is expired, please login from bpc.";
          } else {
            window.location = "/Error?error=" + err.message;
          }
        });
    }
  }

  return (
    <div>
      {props.GpsAvailable === true ?  <div id="viewDiv" ref={mapRef}>
        {callforMap()}
      </div> : <div id="viewDiv">GPS not available</div>}
     
    </div>
  );
};

export default EsriMap;
