import React, { useState } from "react";
import "../../App.css";
import logo from "../../assets/bplogofull.png";
import axios from "axios";
import Spinbar from "../../components/Spinner";
import "../../components/Login/PdiLogin.css";
import { Link } from "react-router-dom";

const ChangePassword = () => {
  const [bindError, setError] = useState(null);
  const [bindresponse, setSuccessResponse] = useState(null);
  const [url, setUrl] = useState(process.env.REACT_APP_URL);
  const [isLoading, setIsLoading] = React.useState(false);

  function submitUserPassword() {
    try {
      var Username = document.getElementById("txtUsername").value;
      var oldPassword = document.getElementById("txtOldpassword").value;
      var password = document.getElementById("txtpassword").value;
      var confirmPassword = document.getElementById("txtConfirmpassword").value;
      document.getElementById("txtUsername").style.border = "none";
      document.getElementById("txtOldpassword").style.border = "none";
      document.getElementById("txtpassword").style.border = "none";
      document.getElementById("txtConfirmpassword").style.border = "none";
      setError(null);
      setSuccessResponse(null);
      if (Username === null || Username === "") {
        document.getElementById("txtUsername").style.border = "1px solid red";
        document.getElementById("txtUsername").focus();
        return false;
      } else if (oldPassword === null || oldPassword === "") {
        document.getElementById("txtOldpassword").style.border =
          "1px solid red";
        document.getElementById("txtOldpassword").focus();
        return false;
      } else if (password === null || password === "") {
        document.getElementById("txtpassword").style.border = "1px solid red";
        document.getElementById("txtpassword").focus();
        return false;
      } else if (confirmPassword === null || confirmPassword === "") {
        document.getElementById("txtConfirmpassword").style.border =
          "1px solid red";
        document.getElementById("txtConfirmpassword").focus();
        return false;
      } else if (password.length < 10) {
        document.getElementById("txtpassword").style.border = "1px solid red";
        document.getElementById("txtpassword").focus();
        return false;
      } else if (password !== confirmPassword) {
        document.getElementById("txtConfirmpassword").style.border =
          "1px solid red";
        document.getElementById("txtConfirmpassword").focus();
        return false;
      } else if (!password.match(/[A-Z]/)) {
        document.getElementById("txtpassword").style.border = "1px solid red";
        document.getElementById("txtConfirmpassword").style.border =
          "1px solid red";
        document.getElementById("txtpassword").focus();
        return false;
      } else if (!password.match(/[a-z]/)) {
        document.getElementById("txtpassword").style.border = "1px solid red";
        document.getElementById("txtConfirmpassword").style.border =
          "1px solid red";
        document.getElementById("txtpassword").focus();
        return false;
      } else if (!password.match(/\d/)) {
        document.getElementById("txtpassword").style.border = "1px solid red";
        document.getElementById("txtConfirmpassword").style.border =
          "1px solid red";
        document.getElementById("txtpassword").focus();
        return false;
      } else if (!password.match(/[^a-zA-Z0-9\-\/]/)) {
        document.getElementById("txtpassword").style.border = "1px solid red";
        document.getElementById("txtConfirmpassword").style.border =
          "1px solid red";
        document.getElementById("txtpassword").focus();
        return false;
      } else {
        setIsLoading(true);
        axios
          .post(url + "/auth/UserPassword", {
            user_name: Username,
            password: oldPassword,
            new_password: password,
          })
          .then(function (res) {
            setSuccessResponse("Password changed successfully.");
          })
          .catch(function (err) {
            if (err.response.status === 401) {
              setError("You have entered wrong Login Id or Password !!!");
              setIsLoading(false);
              return false;
            } else {
              setError("Something wrong, Please try after some times.");
              setIsLoading(false);
              return false;
            }
          })
          .finally(function () {
            setIsLoading(false);
          });
      }
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <div className="container">
      {isLoading && (
        <div className="loader">
          <Spinbar color="white" />
        </div>
      )}
      <div className="login-form">
        <div className="panel">
          <img src={logo} id="icon" height="100vmin" alt="Bp Icon" />
          <h2>Change Password</h2>
          <label id="lblError" className="lblapierror">
            {bindError}
          </label>
          <label id="lblSuccess" className="lblapiSuccess">
            {bindresponse}
          </label>
          {/* <p>Use the form below to change your password.</p> */}
          <div>
            <div id="pswd_info">
              <div>Password must be requirements</div>
              <li id="length" className="invalid">
                Be at least <strong>10 Characters Long</strong>
              </li>
              <li id="capital" className="invalid">
                At least <strong>One Uppercase Letter</strong>
              </li>
              <li id="letter" className="invalid">
                At least <strong>One Lowercase Letter</strong>
              </li>
              <li id="number" className="invalid">
                At least <strong>One Number</strong>
              </li>
              <li id="space" className="invalid">
                At least <strong>One Special Character</strong>
              </li>
            </div>
          </div>
        </div>
        <div className="main-div">
          <form id="changePassword">
            <div className="form-group">
              <input
                type="text"
                className="input-lg form-control"
                name="username"
                id="txtUsername"
                placeholder="Login Id"
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="input-lg form-control"
                name="Old password"
                id="txtOldpassword"
                placeholder="Old Password"
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="input-lg form-control"
                name="password"
                id="txtpassword"
                placeholder="New Password"
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="input-lg form-control"
                name="ConfirmPassword"
                id="txtConfirmpassword"
                placeholder="Repeat New Password"
              />
            </div>
            <button
              type="button"
              className="btn btn-success"
              onClick={() => submitUserPassword()}
            >
              Change Password
            </button>
            <Link to="/PdiLogin">Back to Login</Link>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ChangePassword;
