import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./components/Login/Login";
import Order from "./components/Orders/Order";
import Orderdetails from "./components/Orders/Orderdetails";
import ErrorPage from './GetError';
import PDILogin from './components/Login/PdiLogin';
//import Helpdesk from '../../UI/src/help';
import ChangePassword from "./components/Login/ChangePassword";

function App() {
  
  return (
    <div>
     <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Order/>} /> 
         <Route exact path="/OrderDetails" element={<Orderdetails />} />
         <Route exact path="/Login" element={<Login/>} />
         <Route exact path="/Error" element={<ErrorPage/>} />
         <Route exact path="/PDILogin" element={<PDILogin/>} />
         {/* <Route exact path="/Helpdesk" element={<Helpdesk/>} />*/}
         <Route exact path="/ChangePassword" element={<ChangePassword/>} /> 
        </Routes>
      </BrowserRouter>
    </>
     
  </div>
      

  );
}

export default App;
