import React, {useState} from "react";
import "./App.css";

const GetError = () =>
{
    const [url, setUrl] = useState(process.env.REACT_APP_URL);
    return(
        <div className="page-wrap d-flex flex-row align-items-center">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12 text-center">
                    <span className="display-1 d-block">Error</span>
                    <div className="mb-4 lead">Error in Processing request.</div>
                    <a href={url} className="btn btn-link">Back to tlv</a>
                </div>
            </div>
        </div>
    </div>
    )
}
export default GetError;