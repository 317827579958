import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import classes from "./Orderdetails.module.css";
import * as Icon from "react-bootstrap-icons";
import { useNavigate, useLocation } from "react-router-dom";
import EsriMap from "../Map/EsriMap";
import React, { useEffect, useState } from "react";
import DestImage from "../../assets/location.png";
import axios from "axios";


function calculateETAbyTimezone(utcEDate, utcLDate, tzString) {
  try {
    var checkEdateYear = utcEDate.split("-");
    var checkLdateYear = utcLDate.split("-");
    if (
      utcEDate != null &&
      utcEDate != "" &&
      utcEDate != undefined &&
      utcLDate != null &&
      utcLDate != "" &&
      utcLDate != undefined &&
      tzString != null &&
      tzString != "" &&
      tzString != undefined &&
      checkEdateYear[0] != undefined &&
      checkEdateYear[0] != "0001" &&
      checkEdateYear[0] != "" &&
      checkLdateYear[0] != undefined &&
      checkLdateYear[0] != "0001" &&
      checkLdateYear[0] != ""
    ) {
      const options = {
        timeZone: tzString,
        timeZoneName: "short",
        month: "short",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
      };
      const Edate = new Date(utcEDate);
      const Ldate = new Date(utcLDate);
      const Eformatter = new Intl.DateTimeFormat("en-UA", options).format(
        Edate
      );
      const Lformatter = new Intl.DateTimeFormat("en-UA", options).format(
        Ldate
      );
      return Eformatter + " - " + Lformatter;
    }
    // else if (utcEDate != null && utcEDate != "" && utcEDate != undefined) {
    //   const month = [
    //     "Jan",
    //     "Feb",
    //     "Mar",
    //     "Apr",
    //     "May",
    //     "Jun",
    //     "Jul",
    //     "Aug",
    //     "Sep",
    //     "Oct",
    //     "Nov",
    //     "Dec",
    //   ];
    //   var Earliest = new Date(utcEDate);
    //   var Latest = new Date(utcLDate);
    //   var Earliestdd = String(Earliest.getDate()).padStart(2, "0");
    //   var Earliestmm = month[String(Earliest.getMonth())];
    //   var Ehours = Earliest.getHours();
    //   var Eminutes = Earliest.getMinutes();
    //   var Eampm = Ehours >= 12 ? "pm" : "am";
    //   Ehours = Ehours % 12;
    //   Ehours = Ehours ? Ehours : 12; // the hour '0' should be '12'
    //   Eminutes = Eminutes < 10 ? "0" + Eminutes : Eminutes;
    //   var strTimeEarlier = Ehours + ":" + Eminutes + " " + Eampm;

    //   var Latestdd = String(Latest.getDate()).padStart(2, "0");
    //   var Latestmm = month[String(Latest.getMonth())];
    //   var hours = Latest.getHours();
    //   var minutes = Latest.getMinutes();
    //   var ampm = hours >= 12 ? "pm" : "am";
    //   hours = hours % 12;
    //   hours = hours ? hours : 12; // the hour '0' should be '12'
    //   minutes = minutes < 10 ? "0" + minutes : minutes;
    //   var strTimeLatest = hours + ":" + minutes + " " + ampm;
    //   return (
    //     Earliestdd +
    //     " " +
    //     Earliestmm +
    //     ", " +
    //     strTimeEarlier +
    //     " - " +
    //     Latestdd +
    //     " " +
    //     Latestmm +
    //     ", " +
    //     strTimeLatest
    //   );
    // }
    else
    {
      return "No data";
    }
  } catch (err) {
    console.log(err.message);
  }
}

function getStatusName(statusval) {
  if (statusval != null && statusval != "" && statusval != undefined) {
    let statusName;
    try {
      switch (statusval) {
        case -1:
          statusName = "Canceled";
          break;
        case -2:
          statusName = "Expired";
          break;
        case 1:
          statusName = "Generated";
          break;
        case 2:
          statusName = "Driver Assigned";
          break;
        case 3:
          statusName = "On Route To Terminal";
          break;
        case 4:
          statusName = "Loading Started";
          break;
        case 5:
          statusName = "On Route To Delivery";
          break;
        case 6:
          statusName = "Arrived At Station";
          break;
        case 7:
          statusName = "Completed";
          break;
      }
    } catch (err) {
      console.log(err.message);
    }
    return statusName;
  }
}

function AddStatusIcon(inputval) {
  const StatusColorPoint = [];
  if (inputval != "" && inputval != null && inputval != undefined) {
    try {
      if (inputval == "-1") {
        StatusColorPoint.push(<Icon.CircleFill color="red" size={10} />);
      } else if (inputval == "-2") {
        StatusColorPoint.push(<Icon.CircleFill color="red" size={10} />);
      } else if (inputval == "1") {
        StatusColorPoint.push(<Icon.CircleFill color="aqua" size={10} />);
      } else if (inputval == "2") {
        StatusColorPoint.push(<Icon.CircleFill color="aqua" size={10} />);
      } else if (inputval == "3") {
        StatusColorPoint.push(<Icon.CircleFill color="aqua" size={10} />);
      } else if (inputval == "4") {
        StatusColorPoint.push(<Icon.CircleFill color="aqua" size={10} />);
      } else if (inputval == "5") {
        StatusColorPoint.push(<Icon.CircleFill color="aqua" size={10} />);
      } else if (inputval == "6") {
        StatusColorPoint.push(<Icon.CircleFill color="aqua" size={10} />);
      } else if (inputval == "7") {
        StatusColorPoint.push(<Icon.CircleFill color="lightgreen" size={10} />);
      }
    } catch (err) {
      console.log(err.message);
    }
    return StatusColorPoint;
  }
}

function calETAmobile(EarliestDate, LatestDate, tzString) {
  try {
    var chkEdateYear = EarliestDate.split("-");
    var chkLdateYear = LatestDate.split("-");
    if (
      EarliestDate != null &&
      LatestDate != null &&
      tzString != null &&
      EarliestDate != "" &&
      LatestDate != "" &&
      tzString != "" &&
      EarliestDate != undefined &&
      LatestDate != undefined &&
      tzString != undefined &&
      chkEdateYear[0] != undefined &&
      chkEdateYear[0] != "0001" &&
      chkEdateYear[0] != "" &&
      chkLdateYear[0] != undefined &&
      chkLdateYear[0] != "0001" &&
      chkLdateYear[0] != ""
    ) {
      const options = {
        timeZone: tzString,
        timeZoneName: "short",
        hour: "numeric",
        minute: "numeric",
      };
      const Edate = new Date(EarliestDate);
      const Ldate = new Date(LatestDate);
      const Eformatter = new Intl.DateTimeFormat("en-UA", options).format(
        Edate
      );
      const Lformatter = new Intl.DateTimeFormat("en-UA", options).format(
        Ldate
      );
      return Eformatter + " - " + Lformatter;
    } else {
      // var Earliest = new Date(EarliestDate);
      // var Latest = new Date(LatestDate);
      // var hours = Earliest.getHours();
      // var minutes = Earliest.getMinutes();
      // var eampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12; // the hour '0' should be '12'
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      // var strTimeEarlier = hours + ":" + minutes + " " + eampm;

      // var hours = Latest.getHours();
      // var minutes = Latest.getMinutes();
      // var lampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12; // the hour '0' should be '12'
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      // var strTimeLatest = hours + ":" + minutes + " " + lampm;
      // return strTimeEarlier + " - " + strTimeLatest;
      return "No data";
    }
  } catch (err) {
    console.log(err.message);
  }
}

function calETAmobileDaytime(EarliestDate, LatestDate, tzString) {
  try {
    var chkEYear = EarliestDate.split("-");
    var chkLYear = LatestDate.split("-");
    if (
      EarliestDate != null &&
      LatestDate != null &&
      tzString != null &&
      EarliestDate != "" &&
      LatestDate != "" &&
      tzString != "" &&
      EarliestDate != undefined &&
      LatestDate != undefined &&
      tzString != undefined &&
      chkEYear[0] != undefined &&
      chkEYear[0] != "0001" &&
      chkEYear[0] != "" &&
      chkLYear[0] != undefined &&
      chkLYear[0] != "0001" &&
      chkLYear[0] != ""
    ) {
      const options = {
        timeZone: tzString,
        day: "2-digit",
        month: "short",
      };
      const Edate = new Date(EarliestDate);
      const Ldate = new Date(LatestDate);
      const Eformatter = new Intl.DateTimeFormat("en-UA", options).format(
        Edate
      );
      const Lformatter = new Intl.DateTimeFormat("en-UA", options).format(
        Ldate
      );
      return Eformatter + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0" + Lformatter;
    } else {
        return "";
    }
  } catch (err) {
    console.log(err.message);
  }
}

const Orderdetails = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = React.useState([]);
  const [quantitydata, setquantityData] = React.useState([]);
  const [url, setUrl] = useState(process.env.REACT_APP_URL);
  useEffect(() => {
    if (
      location.state.id != null &&
      location.state.id != "" &&
      location.state.id != undefined &&
      location.state.SiteId != null &&
      location.state.SiteId != "" &&
      location.state.SiteId != undefined
    ) {
      axios
      .get(url + "/api/orders/" +
            location.state.id +
            "/" +
            location.state.SiteId
        )
        .then(function (res) {
          setData(res.data);
        })
        .catch(function (err) {
          if (err.response.status === 401) {
            window.location = "/Login?error=" + err.response.data.message;
          } else if (err.response.status === 403) {
            window.location =
              "/Login?error=" + "Your token is expired, please login from bpc.";
          } else {
            window.location = "/Error?error=" + err.message;
          }
        });
      axios
      .get( url + 
           "/api/quantities/" +
            location.state.id +
            "/" +
            location.state.SiteId
        )
        .then(function (res) {
          setquantityData(res.data);
        })
        .catch(function (err) {
          if (err.response.status === 401) {
            window.location = "/Login?error=" + err.response.data.message;
          } else if (err.response.status === 403) {
            window.location =
              "/Login?error=" + "Your token is expired, please login from bpc.";
          } else {
            window.location = "/Error?error=" + err.message;
          }
        });
    }
  }, []);
  function renderDeliveryDetails() {
    const deliveryDetails = [];
    if (data != null && data != "" && data != undefined) {
      deliveryDetails.push(
        <p className="mbr-fonts-style text1 mbr-text">
          <div className={classes.divInline}>
            <Icon.GeoAlt size={11} /> DESTINATION
          </div>
          <div className={classes.divans}>
            {data.DeliveryDetails.Destination
              ? data.DeliveryDetails.Destination
              : "No data"}
          </div>
          <div className="row">
            <div className="col-6 .col-sm-4">
              <div className={classes.divInline}>
                <Icon.Clock size={11} /> ETA
              </div>
            </div>
            <div className="col-6 .col-sm-4">
              {" "}
              <div className={classes.divInline}>
                <Icon.Truck size={11} /> CARRIER
              </div>{" "}
            </div>
          </div>
          <div className="row">
            <div className="col-6 .col-sm-4">
              <div className={classes.divInlineans}>
                <div className="col-sm-12 desktop">
                  {/* {data.DeliveryDetails.EarliestDateTimeUTC
                    ? etaDate(
                      data.DeliveryDetails.EarliestDateTimeUTC,
                      data.DeliveryDetails.LatestDateTimeUTC
                    )
                    : "No data"} */}
                  {data.DeliveryDetails.EarliestDateTimeUTC
                    ? calculateETAbyTimezone(
                        data.DeliveryDetails.EarliestDateTimeUTC,
                        data.DeliveryDetails.LatestDateTimeUTC,
                        data.DeliveryDetails.TimeZone
                      )
                    : "No data"}
                </div>
                <div className="col-sm-12 mobile">
                  {data.DeliveryDetails.EarliestDateTimeUTC
                    ? calETAmobile(
                        data.DeliveryDetails.EarliestDateTimeUTC,
                        data.DeliveryDetails.LatestDateTimeUTC,
                        data.DeliveryDetails.TimeZone
                      )
                    : "No data"}
                  <p>
                    {data.DeliveryDetails.EarliestDateTimeUTC
                      ? calETAmobileDaytime(
                          data.DeliveryDetails.EarliestDateTimeUTC,
                          data.DeliveryDetails.LatestDateTimeUTC,
                          data.DeliveryDetails.TimeZone
                        )
                      : ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 .col-sm-4">
              {" "}
              <div className={classes.divInlineans}>
                <div>
                  {data.DeliveryDetails.Carrier
                    ? data.DeliveryDetails.Carrier
                    : "No data"}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6 .col-sm-4">
              <div className={classes.divInline}>
                <Icon.CardText size={11} /> ORDER
              </div>
            </div>
            <div className="col-6 .col-sm-4">
              {" "}
              <div className={classes.divInline}>
                {/* <Icon.CircleFill color="lightgreen" /> STATUS */}
                {AddStatusIcon(data.DeliveryDetails.OrderStatus)} STATUS
              </div>{" "}
            </div>
          </div>
          <div className="row">
            <div className="col-6 .col-sm-4">
              <div className={classes.divInlineans}>
                <div className="col-sm-12">
                  {data.DeliveryDetails.OrderId
                    ? data.DeliveryDetails.OrderId
                    : "No data"}
                </div>
              </div>
            </div>
            <div className="col-6 .col-sm-4">
              {" "}
              <div className={classes.divInlineans}>
                <div className="col-sm-12">
                  {data.DeliveryDetails.OrderStatus
                    ? getStatusName(data.DeliveryDetails.OrderStatus)
                    : "No data"}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6 .col-sm-4">
              <div className={classes.divInline}>
                <Icon.ArchiveFill size={11} /> SITE ID
              </div>
            </div>
            <div className="col-6 .col-sm-4">
              {" "}
              <div className={classes.divInline}>
                <Icon.Clock /> ORDER CREATION DATE
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 .col-sm-4">
              <div className={classes.divInlineans}>
                <div className="col-sm-12">
                  {data.DeliveryDetails.SiteId
                    ? data.DeliveryDetails.SiteId
                    : "No data"}
                </div>
              </div>
            </div>
            <div className="col-6 .col-sm-4">
              {" "}
              <div className={classes.divInlineans}>
                <div>
                  {data.DeliveryDetails.InsertDatetimeUTC
                    ? changeDateFormat(data.DeliveryDetails.InsertDatetimeUTC, data.DeliveryDetails.TimeZone)
                    : "No data"}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 .col-sm-4">
              <div className={classes.divInline}>
                <Icon.ArchiveFill size={11} /> SITE NAME
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className={classes.divInlineans}>
                <div className="col-sm-12">
                  {data.DeliveryDetails.SiteName
                    ? data.DeliveryDetails.SiteName
                    : "No data"}
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div className="row">
            <div className="col-6 .col-sm-4">
              <div className={classes.divInline}>
                <Icon.Clock size={11} /> AT RACK
              </div>
            </div>
            <div className="col-6 .col-sm-4">
              {" "}
              <div className={classes.divInline}>
                <Icon.Clock /> LEFT RACK
              </div>{" "}
            </div>
          </div>
          <div className="row">
            <div className="col-6 .col-sm-4">
              <div className={classes.divInlineans}>
                <div className="col-sm-12">
                  {data.DeliveryDetails.AtRack
                    ? changeDateFormatwithtime(data.DeliveryDetails.AtRack, data.DeliveryDetails.TimeZone)
                    : "No data"}
                </div>
              </div>
            </div>
            <div className="col-6 .col-sm-4">
              {" "}
              <div className={classes.divInlineans}>
                <div className="col-sm-12">
                  {data.DeliveryDetails.LeftRack
                    ? changeDateFormatwithtime(data.DeliveryDetails.LeftRack, data.DeliveryDetails.TimeZone)
                    : "No data"}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6 .col-sm-4">
              <div className={classes.divInline}>
                <Icon.Clock size={11} /> AT SITE
              </div>
            </div>
            <div className="col-6 .col-sm-4">
              {" "}
              <div className={classes.divInline}>
                <Icon.Clock /> LEFT SITE
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 .col-sm-4">
              <div className={classes.divInlineans}>
                <div className="col-sm-12">
                  {data.DeliveryDetails.AtSite
                    ? changeDateFormatwithtime(data.DeliveryDetails.AtSite, data.DeliveryDetails.TimeZone)
                    : "No data"}
                </div>
              </div>
            </div>
            <div className="col-6 .col-sm-4">
              {" "}
              <div className={classes.divInlineans}>
                <div>
                  {data.DeliveryDetails.LeftSite
                    ? changeDateFormatwithtime(data.DeliveryDetails.LeftSite, data.DeliveryDetails.TimeZone)
                    : "No data"}
                </div>
              </div>
            </div>
          </div>
        </p>
      );

      return deliveryDetails;
    }
  }

  function loadQuantitydetails() {
    const deliveryDetails = [];
    if (
      quantitydata != null &&
      quantitydata != "" &&
      quantitydata != undefined
    ) {
      if (quantitydata.length > 0) {
        for (var i = 0; i < quantitydata.length; i++) {
          deliveryDetails.push(
            <tr>
              <td>{quantitydata[i].productGrade}</td>
              <td>{quantitydata[i].quantity} {quantitydata[i].quantity != "TBD" ? "gal" : "" }</td>
            </tr>
          );
        }
      } else {
        <div>No data</div>;
      }
    }
    return deliveryDetails;
  }

  function changeDateFormat(utcdate, tzString) {
    var chkorderCreationdate = utcdate.split("-");
    if (utcdate != "" && utcdate != null && utcdate != undefined && tzString != null && tzString != "" && tzString != undefined && chkorderCreationdate != null  && chkorderCreationdate != "0001" && chkorderCreationdate != "" && chkorderCreationdate != undefined) {
      const options = {
        timeZone: tzString,
        timeZoneName: "short",
        year: 'numeric', 
        month: "long",
        day: "2-digit",
      };
      const date = new Date(utcdate);
      const formatter = new Intl.DateTimeFormat("en-UA", options).format(date);
      return formatter;
    }
    // else if(utcdate != "" && utcdate != null)
    // {
    //   const month = [
    //     "January",
    //     "February",
    //     "March",
    //     "April",
    //     "May",
    //     "June",
    //     "July",
    //     "August",
    //     "September",
    //     "October",
    //     "November",
    //     "December",
    //   ];
    //   const Ldate = new Date(utcdate);
    //   let monthname = month[Ldate.getMonth()];
    //   let Dateval = Ldate.getDate();
    //   let yearVal = Ldate.getFullYear();
    //   return monthname + " " + Dateval + ", " + yearVal;
    // }
    else{
      return "No data"
    }
  }
  function changeDateFormatwithtime(utcdate, tzString) {
    if (utcdate != "" && utcdate != null && utcdate != undefined && tzString != "" && tzString != null && tzString != undefined) {
     
      const options = {
        timeZone: tzString,
        timeZoneName: "short",
        hour: "numeric",
        minute: "numeric",
      };
      const date = new Date(utcdate);
      const formatter = new Intl.DateTimeFormat("en-UA", options).format(date);
      return formatter;
    }
    else if(utcdate != "" && utcdate != null)
    {
       var date = new Date(utcdate);
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;    
    }
    else{
      return "No data"
    }
  }
  return (
    <React.Fragment>
      <Header />
      <div>
        <div className="row border-bottom divcolordetails">
        <div className="col-6 divmarginnpadding">
              <a
                className="btnOrderdetailsreturn" href="javascript:void(0)"
                onClick={() => navigate(-1)}
              >
                <Icon.ArrowLeft /> Order {location.state.id}
              </a>
          </div>
          <div className="col">
            <p className={classes.fontSizexs}>{location.state.lastupdate}</p>
          </div>
          <div className="col-1"></div>
        </div>
        <div className={classes.detailsbgchange}>
          <div className="row rowdetails">
            <div className="col-md-6">
              <div className="wrap">
                <div className="text-wrap">
                  <h6>
                    <b>DELIVERY DETAILS</b>
                  </h6>
                  {renderDeliveryDetails()}
                </div>
              </div>
              <div className="wrap">
                <div className="text-wrap">
                  <h6>
                    <b>QUANTITIES</b>
                  </h6>
                  <div className={classes.tableHeadFixed}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Product</th>
                          <th scope="col">Scheduled</th>
                        </tr>
                      </thead>
                      <tbody>{loadQuantitydetails()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="wrap">
                <div className="text-wrap vcenter">
                  <div className="row">
                    <div className="col-sm-2">
                      <h6>
                        <b>MAP</b>
                      </h6>
                    </div>
                    <div className="col-sm-10">
                      <p className={classes.textalignright}>
                        (Origin : <Icon.CircleFill color="Lime" height="12px" />{" "}
                        , Destination :{" "}
                        <img
                          src={DestImage}
                          alt="Destination point"
                          width="20px"
                        ></img>
                        )
                      </p>
                    </div>
                  </div>
                  <div>
                    <EsriMap
                      OrderId={location.state.id}
                      SiteId={location.state.SiteId}
                      GpsAvailable={location.state.GpsAvailable}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Orderdetails;
