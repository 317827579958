import React, { useState } from "react";
import logo from "../../assets/bplogofull.png";
import "../../components/Login/PdiLogin.css";
import axios from "axios";
import Spinbar from "../../components/Spinner";
import { Link } from "react-router-dom";
import "../../App.css";

const PdiLogin = () => {
  const [url, setUrl] = useState(process.env.REACT_APP_URL);
  const [bindError, setError] = useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  function checkUserCredentials() {
    var txtLoginIdVal = document.getElementById("txtloginid").value;
    var txtpwdval = document.getElementById("txtpassword").value;
    document.getElementById("txtloginid").style.border = "none";
    document.getElementById("txtpassword").style.border = "none";
    setError(null);
    if (
      txtLoginIdVal == "" ||
      txtLoginIdVal == null ||
      txtLoginIdVal == undefined
    ) {
      document.getElementById("txtloginid").style.border = "1px solid red";
      return false;
    } else if (txtpwdval == "" || txtpwdval == null || txtpwdval == undefined) {
      document.getElementById("txtpassword").style.border = "1px solid red";
      return false;
    } else {
      setIsLoading(true);
      axios
        .post(url + "/auth/UserPassword", {
          user_name: txtLoginIdVal,
          password: txtpwdval,
        })
        .then(function (res) {
          window.location = "/";
        })
        .catch(function (err) {
          if (err.response.status === 401) {
            setError("You have entered wrong Login Id or Password !!!");
            setIsLoading(false);
            return false;
          }
         else if (err.response.status === 500) {
            setError("You have entered wrong Login Id or Password !!!");
            setIsLoading(false);
            return false;
          }  
          else {
            setError(err.response.data.error);
            //window.location = "/Error?error=" + err.message;
          }
        })
        .finally(function () {
          setIsLoading(false);
        });
    }
  }

  return (
    <div>
      {isLoading ? (
        <div className="loader">
          <Spinbar color="white" />
        </div>
      ) : (
        <div className="container">
          <div className="login-form">
            <div className="panel">
              <img src={logo} id="icon" height="150vmin" alt="Bp Icon" />
              <h2>TLV Login</h2>
              <label id="lblError" className="lblloginerror">
                {bindError}
              </label>
              <p>Please enter your login id and password</p>
            </div>
            <div className="main-div">
              <form id="Login">
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    id="txtloginid"
                    placeholder="Login id"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    id="txtpassword"
                    placeholder="Password"
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => checkUserCredentials()}
                >
                  Login
                </button>
                <Link to="/ChangePassword">Change Password</Link>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default PdiLogin;
