import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import axios from "axios";
import Modal from "../../components/Orders/Modal";
import HistoryModal from "../../components/Orders/Modal2";
import { Spinner } from "reactstrap";
import ColumnFilter from "../../components/Orders/ModalColumn";

function calLastUpdateTime(utcTime) {
  var result;
  if (utcTime != null && utcTime != "" && utcTime != undefined) {
    var now = new Date();
    var endDate = new Date(utcTime);
    var diff = now - endDate;
    var hours = Math.floor(diff / 3.6e6);
    var minutes = Math.floor((diff % 3.6e6) / 6e4);
    //var seconds = Math.floor((diff % 6e4) / 1000);
    if (hours < 24) {
      if (hours > 0) {
        result = hours + " hour, " + minutes + " minutes " + "ago";
      } else {
        result = minutes + " minutes " + "ago";
      }
    } else if (hours >= 24) {
      let Difference_In_Time = now.getTime() - endDate.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      var TotalDays = Difference_In_Days.toString().split(".")[0];
      result = TotalDays + " Days";
    } else {
      result = hours + " hour, " + minutes + " minutes " + "ago";
    }

    return result;
  }
}

function getStatusval(statusval) {
  if (statusval != null && statusval != "" && statusval != undefined) {
    let statusName;
    statusName = statusval.split(" ").join("");
    try {
      switch (statusval) {
        case "Canceled":
          statusName = "-1";
          break;
        case "Generated":
          statusName = "1";
          break;
        case "Driver Assigned":
          statusName = "2";
          break;
        case "On Route To Terminal":
          statusName = "3";
          break;
        case "Loading Started":
          statusName = "4";
          break;
        case "On Route To Delivery":
          statusName = "5";
          break;
        case "Arrived At Station":
          statusName = "6";
          break;
        case "Completed":
          statusName = "7";
          break;
      }
    } catch (err) {
      console.log(err.message);
    }
    return statusName;
  }
}

// function calETA(EarliestDate, LatestDate) {
//   if (
//     EarliestDate != null &&
//     LatestDate != null &&
//     EarliestDate != "" &&
//     LatestDate != ""
//   ) {
//     try {
//       const month = [
//         "January",
//         "February",
//         "March",
//         "April",
//         "May",
//         "June",
//         "July",
//         "August",
//         "September",
//         "October",
//         "November",
//         "December",
//       ];
//       var Earliest = new Date(EarliestDate);
//       var Latest = new Date(LatestDate);
//       var Earliestdd = String(Earliest.getDate()).padStart(2, "0");
//       var Earliestmm = month[String(Earliest.getMonth())];
//       var Ehours = Earliest.getHours();
//       var Eminutes = Earliest.getMinutes();
//       var Eampm = Ehours >= 12 ? "pm" : "am";
//       Ehours = Ehours % 12;
//       Ehours = Ehours ? Ehours : 12; // the hour '0' should be '12'
//       Eminutes = Eminutes < 10 ? "0" + Eminutes : Eminutes;
//       var strTimeEarlier = Ehours + ":" + Eminutes + " " + Eampm;

//       var Latestdd = String(Latest.getDate()).padStart(2, "0");
//       var Latestmm = month[String(Latest.getMonth())];
//       var hours = Latest.getHours();
//       var minutes = Latest.getMinutes();
//       var ampm = hours >= 12 ? "pm" : "am";
//       hours = hours % 12;
//       hours = hours ? hours : 12; // the hour '0' should be '12'
//       minutes = minutes < 10 ? "0" + minutes : minutes;
//       var strTimeLatest = hours + ":" + minutes + " " + ampm;
//     } catch (err) {
//       console.log(err.message);
//     }
//     return (
//       strTimeEarlier +
//       ", " +
//       Earliestdd +
//       " " +
//       Earliestmm +
//       " - " +
//       strTimeLatest +
//       ", " +
//       Latestdd +
//       " " +
//       Latestmm
//     );
//   }
// }

function AddStatusIcon(inputval) {
  const StatusColorPoint = [];
  if (inputval != "" && inputval != null && inputval != undefined) {
    try {
      if (inputval == "-1") {
        StatusColorPoint.push(
          <Icon.CircleFill className="Statusiconmargin" color="red" size={10} />
        );
      } else if (inputval == "-2") {
        StatusColorPoint.push(
          <Icon.CircleFill className="Statusiconmargin" color="red" size={10} />
        );
      } else if (inputval == "1") {
        StatusColorPoint.push(
          <Icon.CircleFill
            className="Statusiconmargin"
            color="aqua"
            size={10}
          />
        );
      } else if (inputval == "2") {
        StatusColorPoint.push(
          <Icon.CircleFill
            className="Statusiconmargin"
            color="aqua"
            size={10}
          />
        );
      } else if (inputval == "3") {
        StatusColorPoint.push(
          <Icon.CircleFill
            className="Statusiconmargin"
            color="aqua"
            size={10}
          />
        );
      } else if (inputval == "4") {
        StatusColorPoint.push(
          <Icon.CircleFill
            className="Statusiconmargin"
            color="aqua"
            size={10}
          />
        );
      } else if (inputval == "5") {
        StatusColorPoint.push(
          <Icon.CircleFill
            className="Statusiconmargin"
            color="aqua"
            size={10}
          />
        );
      } else if (inputval == "6") {
        StatusColorPoint.push(
          <Icon.CircleFill
            className="Statusiconmargin"
            color="aqua"
            size={10}
          />
        );
      } else if (inputval == "7") {
        StatusColorPoint.push(
          <Icon.CircleFill
            className="Statusiconmargin"
            color="lightgreen"
            size={10}
          />
        );
      }
    } catch (err) {
      console.log(err.message);
    }
    return StatusColorPoint;
  }
}

const OrderTable = (props) => {
  const [currentUser, setCurrentUser] = useState(false);
  const [show, setShow] = useState(false);
  const [modalshow, setShowmodal] = useState(false);
  const [masterData, setMasterData] = useState([]);
  const [data, setData] = useState([]);
  const [post, setPost] = useState(null);
  const [count, setCount] = useState(0);
  const [url, setUrl] = useState(process.env.REACT_APP_URL);
  const [isLoading, setIsLoading] = useState(false);
  const [showPrev, setShowPrev] = useState(true);
  const [showNext, setShowNext] = useState(true);
  const [nextkeyval, setNextKeyval] = useState(null);
  const [filterUrl, setfilterUrl] = useState();
  const [stackCount, setStackCount] = useState(0);
  const [stack, setStack] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(null);
  const [totalDataCountcheck, setTotalDataCountcheck] = useState(null);
  const [dataCount, setDataCount] = useState(null);
  const [isWestcoast, setIswestcoast] = useState(false);
  const [columnFiltershow, setShowColumnFilter] = useState(false);
  const [userSettingData, setSettingData] = React.useState([]);

  useEffect(() => {
    setfilterUrl(url + "/api/orders?");
    setIsLoading(true);
    axios
      .get(url)
      .then(function (res) {
        setTimeout(function () {
          setData(res.data);
          setMasterData(res.data);
          setCurrentUser(res.data);
          setIsLoading(false);
        }, 500);
        setTotalDataCount(res.headers.totalrecords);
        setTotalDataCountcheck(res.headers.totalrecords);
        //setIswestcoast(res.headers.iswestcoast);
        setPost(res);
        binddatacount(res.headers.totalrecords);
        if (res.headers.nextkey != null && res.headers.nextkey != "") {
          setShowNext(false);
          setNextKeyval(res.headers.nextkey);
        } else {
          setShowNext(true);
        }
      })
      .catch(function (err) {
        if (err.response.status === 401) {
          window.location = "/Login?error=" + err.response.data.message;
        } else if (err.response.status === 403) {
          window.location =
            "/Login?error=" + "Your token is expired, please login from bpc.";
        } else {
          window.location = "/Error?error=" + err.message;
        }
      })
      .finally(() => {
        setTimeout(function () {
          setIsLoading(false);
        }, 500);
      });

    axios
      .get(url + "/api/getusersettings")
      .then(function (res) {
        setSettingData(res.data);
        // localStorage.setItem("sessioncheckedSettings", res.data.resultSettings);
      })
      .catch(function (err) {
        if (err.response.status === 401) {
          window.location = "/Login?error=" + err.response.data.message;
        } else if (err.response.status === 403) {
          window.location =
            "/Login?error=" + "Your token is expired, please login from bpc.";
        } else {
          window.location = "/Error?error=" + err.message;
        }
      });
  }, []);

  useEffect(() => {
    if (count == 0) {
      bindSelectedItems();
      checkbtnPrevstatus();
    }
  });

  function binddatacount(param, page) {
    var orderList = localStorage.getItem("sessionOrderPagePrevKey");
    if (parseInt(param) == "0") {
      setDataCount("0");
    } else if (parseInt(param) <= 1000 && orderList != null && page == 1) {
      setDataCount("1 - " + param);
    } else if (parseInt(param) <= 1000 && orderList == null) {
      setDataCount("1 - " + param);
    } else if (orderList != null && orderList != "") {
      var str_array = orderList.split(",");
      var listlength = str_array.length;
      if (listlength > 0) {
        var endcount = listlength + 1;
        var endNum = endcount + "000";
        if (parseInt(endNum) < parseInt(param)) {
          setDataCount(listlength + "001" + " - " + endcount + "000");
        } else {
          setDataCount(listlength + "001" + " - " + param);
        }
      }
    } else if (parseInt(param) == 1000 && page == undefined) {
      setDataCount("1 - " + param);
    } else if (parseInt(param) > 1000) {
      setDataCount("1 - 1000");
    }
  }
  function checkbtnPrevstatus() {
    try {
      var prevKeyVal = localStorage.getItem("sessionOrderPagePrevKey");
      if (prevKeyVal != null && prevKeyVal != "") {
        var getvalist = prevKeyVal.split(",");
        setStack(getvalist);
        setShowPrev(false);
      } else {
        setShowPrev(true);
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  function getStatusName(statusval) {
    if (statusval != null && statusval != "" && statusval != undefined) {
      let statusName;
      try {
        switch (statusval) {
          case -1:
            statusName = "Canceled";
            break;
          case -2:
            statusName = "Expired";
            break;
          case 1:
            statusName = "Generated";
            break;
          case 2:
            statusName = "Driver Assigned";
            break;
          case 3:
            statusName = "On Route To Terminal";
            break;
          case 4:
            statusName = "Loading Started";
            break;
          case 5:
            statusName = "On Route To Delivery";
            break;
          case 6:
            statusName = "Arrived At Station";
            break;
          case 7:
            statusName = "Completed";
            break;
        }
      } catch (err) {
        console.log(err.message);
      }
      return statusName;
    }
  }

  function calculateETAbyTimezone(utcEDate, utcLDate, tzString) {
    try {
      var checkEdateYear = utcEDate.split("-");
      var checkLdateYear = utcLDate.split("-");
      if (
        utcEDate != null &&
        utcEDate != "" &&
        utcEDate != undefined &&
        utcLDate != null &&
        utcLDate != "" &&
        utcLDate != undefined &&
        tzString != null &&
        tzString != "" &&
        tzString != undefined &&
        checkEdateYear[0] != undefined &&
        checkEdateYear[0] != "0001" &&
        checkEdateYear[0] != "" &&
        checkLdateYear[0] != undefined &&
        checkLdateYear[0] != "0001" &&
        checkLdateYear[0] != ""
      ) {
        const options = {
          timeZone: tzString,
          timeZoneName: "short",
          month: "short",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
        };
        const Edate = new Date(utcEDate);
        const Ldate = new Date(utcLDate);
        const Eformatter = new Intl.DateTimeFormat("en-UA", options).format(
          Edate
        );
        const Lformatter = new Intl.DateTimeFormat("en-UA", options).format(
          Ldate
        );
        return Eformatter + " - " + Lformatter;
      }
      // else if (utcEDate != null && utcEDate != "" && utcEDate != undefined) {
      //   const month = [
      //     "January",
      //     "February",
      //     "March",
      //     "April",
      //     "May",
      //     "June",
      //     "July",
      //     "August",
      //     "September",
      //     "October",
      //     "November",
      //     "December",
      //   ];
      //   var Earliest = new Date(utcEDate);
      //   var Latest = new Date(utcLDate);
      //   var Earliestdd = String(Earliest.getDate()).padStart(2, "0");
      //   var Earliestmm = month[String(Earliest.getMonth())];
      //   var Ehours = Earliest.getHours();
      //   var Eminutes = Earliest.getMinutes();
      //   var Eampm = Ehours >= 12 ? "pm" : "am";
      //   Ehours = Ehours % 12;
      //   Ehours = Ehours ? Ehours : 12; // the hour '0' should be '12'
      //   Eminutes = Eminutes < 10 ? "0" + Eminutes : Eminutes;
      //   var strTimeEarlier = Ehours + ":" + Eminutes + " " + Eampm;

      //   var Latestdd = String(Latest.getDate()).padStart(2, "0");
      //   var Latestmm = month[String(Latest.getMonth())];
      //   var hours = Latest.getHours();
      //   var minutes = Latest.getMinutes();
      //   var ampm = hours >= 12 ? "pm" : "am";
      //   hours = hours % 12;
      //   hours = hours ? hours : 12; // the hour '0' should be '12'
      //   minutes = minutes < 10 ? "0" + minutes : minutes;
      //   var strTimeLatest = hours + ":" + minutes + " " + ampm;
      //   return (
      //     Earliestdd +
      //     " " +
      //     Earliestmm +
      //     ", " +
      //     strTimeEarlier +
      //     " - " +
      //     Latestdd +
      //     " " +
      //     Latestmm +
      //     ", " +
      //     strTimeLatest
      //   );
      // }
      else {
        return "No data";
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  function findArconumber(SiteName) {
    var lastChar = SiteName.substr(SiteName.length - 1);
    if (lastChar.length <= 1) {
      if (!isNaN(lastChar)) {
        return SiteName.split("-").pop();
      } else {
        return "No data";
      }
    }
  }

  function calculateETAbyTimezoneformobile(EarliestDate, LatestDate, tzString) {
    try {
      var chkEdateYear = EarliestDate.split("-");
      var chkLdateYear = LatestDate.split("-");
      if (
        EarliestDate != null &&
        LatestDate != null &&
        tzString != null &&
        EarliestDate != "" &&
        LatestDate != "" &&
        tzString != "" &&
        EarliestDate != undefined &&
        LatestDate != undefined &&
        tzString != undefined &&
        chkEdateYear[0] != undefined &&
        chkEdateYear[0] != "0001" &&
        chkEdateYear[0] != "" &&
        chkLdateYear[0] != undefined &&
        chkLdateYear[0] != "0001" &&
        chkLdateYear[0] != ""
      ) {
        const options = {
          timeZone: tzString,
          timeZoneName: "short",
          hour: "numeric",
          minute: "numeric",
        };
        const Edate = new Date(EarliestDate);
        const Ldate = new Date(LatestDate);
        const Eformatter = new Intl.DateTimeFormat("en-UA", options).format(
          Edate
        );
        const Lformatter = new Intl.DateTimeFormat("en-UA", options).format(
          Ldate
        );
        return Eformatter + " - " + Lformatter;
      }
      // else if (
      //   EarliestDate != null &&
      //   EarliestDate != "" &&
      //   EarliestDate != undefined
      // ) {
      //   var Earliest = new Date(EarliestDate);
      //   var Latest = new Date(LatestDate);
      //   var Ehours = Earliest.getHours();
      //   var Eminutes = Earliest.getMinutes();
      //   var Eampm = Ehours >= 12 ? "pm" : "am";
      //   Ehours = Ehours % 12;
      //   Ehours = Ehours ? Ehours : 12; // the hour '0' should be '12'
      //   Eminutes = Eminutes < 10 ? "0" + Eminutes : Eminutes;
      //   var strTimeEarlier = Ehours + ":" + Eminutes + " " + Eampm;

      //   var Lhours = Latest.getHours();
      //   var Lminutes = Latest.getMinutes();
      //   var Lampm = Lhours >= 12 ? "pm" : "am";
      //   Lhours = Lhours % 12;
      //   Lhours = Lhours ? Lhours : 12; // the hour '0' should be '12'
      //   Lminutes = Lminutes < 10 ? "0" + Lminutes : Lminutes;
      //   var strTimeLatest = Lhours + ":" + Lminutes + " " + Lampm;
      //   return strTimeEarlier + " - " + strTimeLatest;
      // }
      else {
        return "No data";
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  function ShowDateforEtamobile(EarliestDate, LatestDate, tzString) {
    try {
      var chkEYear = EarliestDate.split("-");
      var chkLYear = LatestDate.split("-");
      if (
        EarliestDate != null &&
        LatestDate != null &&
        tzString != null &&
        EarliestDate != "" &&
        LatestDate != "" &&
        tzString != "" &&
        EarliestDate != undefined &&
        LatestDate != undefined &&
        tzString != undefined &&
        chkEYear[0] != undefined &&
        chkEYear[0] != "0001" &&
        chkEYear[0] != "" &&
        chkLYear[0] != undefined &&
        chkLYear[0] != "0001" &&
        chkLYear[0] != ""
      ) {
        const options = {
          timeZone: tzString,
          day: "2-digit",
          month: "short",
        };
        const Edate = new Date(EarliestDate);
        const Ldate = new Date(LatestDate);
        const Eformatter = new Intl.DateTimeFormat("en-UA", options).format(
          Edate
        );
        const Lformatter = new Intl.DateTimeFormat("en-UA", options).format(
          Ldate
        );
        return Eformatter + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0" + Lformatter;
      }
      // else if (
      //   EarliestDate != null &&
      //   EarliestDate != "" &&
      //   EarliestDate != undefined
      // ) {
      //   const month = [
      //     "Jan",
      //     "Feb",
      //     "Mar",
      //     "Apr",
      //     "May",
      //     "Jun",
      //     "Jul",
      //     "Aug",
      //     "Sept",
      //     "Oct",
      //     "Nov",
      //     "Dec",
      //   ];
      //   var Earliest = new Date(EarliestDate);
      //   var Latest = new Date(LatestDate);
      //   var Earliestdd = String(Earliest.getDate()).padStart(2, "0");
      //   var Earliestmm = month[String(Earliest.getMonth())];

      //   var Latestdd = String(Latest.getDate()).padStart(2, "0");
      //   var Latestmm = month[String(Latest.getMonth())];
      //   return (
      //     Earliestdd +
      //     " " +
      //     Earliestmm +
      //     "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
      //     Latestdd +
      //     " " +
      //     Latestmm
      //   );
      // }
      else {
        return "";
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  function renderOrders() {
    const OrdersList = [];
    if (data.length > 0 && data !== "nodata" && data.length !== 1210) {
      try {
        for (let i = 0; i < data.length; i++) {
          OrdersList.push(
            <tr className="trDays">
              <td colSpan={7} className="tdfiltercheck">
                {" "}
                <span className="divDays">
                  {myDate(data[i].date + " 00:00:00")}
                </span>
                <span className="divdate">
                  {changeDateFormat(data[i].date + " 00:00:00")}
                </span>
              </td>
            </tr>
          );
          if (
            data[i].orders.length > 0 &&
            data[i].orders !== undefined &&
            data[i].orders !== NaN
          ) {
            for (let j = 0; j < data[i].orders.length; j++) {
              OrdersList.push(
                <tr
                  className="tddiv"
                  onClick={() =>
                    navigate("/Orderdetails", {
                      state: {
                        id: data[i].orders[j].OrderId,
                        lastupdate: calLastUpdateTime(
                          data[i].orders[j].UpdateDateTimeUTC
                        ),
                        SiteId: data[i].orders[j].SiteId,
                        GpsAvailable: data[i].orders[j].GpsAvailable,
                      },
                    })
                  }
                >
                  <td className="tdbold Dest_col">
                    <span className="box"></span>{" "}
                    {data[i].orders[j].Destination} - {data[i].orders[j].SiteId}
                    {/* {isWestcoast === "true"
                      ? data[i].orders[j].Destination
                      : data[i].orders[j].Destination -
                        data[i].orders[j].SiteId}*/}
                  </td>
                  {/* {isWestcoast === "true" ? (
                    <td className="sidenumberwidth SiteNumber_col">
                      {data[i].orders[j].SiteId}
                    </td>
                  ) : (
                    ""
                  )}*/}
                  <td className="tdwidth Eta_col">
                    {/* {
                      calETA(
                        data[i].orders[j].EarliestDateTimeUTC,
                        data[i].orders[j].LatestDateTimeUTC
                      )
                      // etaDate(
                      //   data[i].orders[j].EarliestDateTimeUTC,
                      //   data[i].orders[j].LatestDateTimeUTC
                      // )
                    } */}
                    {data[i].orders[j].EarliestDateTimeUTC
                      ? calculateETAbyTimezone(
                          data[i].orders[j].EarliestDateTimeUTC,
                          data[i].orders[j].LatestDateTimeUTC,
                          data[i].orders[j].TimeZone
                        )
                      : "No data"}
                  </td>
                  <td className="tdwidth Carrier_col">
                    {data[i].orders[j].Carrier
                      ? data[i].orders[j].Carrier
                      : "No data"}
                  </td>
                  <td className="SiteName_col">
                    {data[i].orders[j].SiteName
                      ? data[i].orders[j].SiteName
                      : "No data"}
                  </td>
                  {isWestcoast === "true" ? (
                    <td className="ArcoNumber_col">
                      {data[i].orders[j].SiteName
                        ? findArconumber(data[i].orders[j].SiteName)
                        : "No data"}
                    </td>
                  ) : (
                    ""
                  )}

                  {data[i].orders[j].GpsAvailable === true ? (
                    <td className="tdwidthorder Order_col">
                      {data[i].orders[j].OrderId}
                    </td>
                  ) : (
                    <td className="tdwidthorder clrAmber Order_col">
                      {data[i].orders[j].OrderId}
                    </td>
                  )}

                  <td className="tdwidthstatus Status_col">
                    {AddStatusIcon(data[i].orders[j].OrderStatus)}
                    {getStatusName(data[i].orders[j].OrderStatus)}
                  </td>
                  <td className="tdwidth Updated_col">
                    {calLastUpdateTime(data[i].orders[j].UpdateDateTimeUTC)}
                  </td>
                  <td className="arrright">
                    {data[i].orders[j].GpsAvailable === true ? (
                      <Icon.ArrowRight />
                    ) : (
                      <Icon.ExclamationTriangleFill
                        title="No GPS"
                        color="#FFBF00"
                      />
                    )}
                  </td>
                </tr>
              );
            }
          } else {
            OrdersList.push(
              <tr className="trDays">
                <td colSpan={7}>
                  <span className="box"></span>No Orders
                </td>
              </tr>
            );
          }
        }
      } catch (err) {
        console.log(err.message);
      }
    } else if (data.length == 1210) {
      //Not required to implement here
    } else {
      OrdersList.push(
        <tr className="trDays">
          <td colSpan={7}>
            <span className="box"></span>No Orders
          </td>
        </tr>
      );
    }
    return OrdersList;
  }

  function renderOrdersformobile() {
    const OrdersListformobile = [];
    if (
      data.length > 0 &&
      data != "nodata" &&
      data !== undefined &&
      data !== NaN
    ) {
      try {
        for (let i = 0; i < data.length; i++) {
          OrdersListformobile.push(
            <div className="col coldays">
              <span className="divDays">
                {myDate(data[i].date + " 00:00:00")}
              </span>
              <span className="divdate">
                {changeDateFormat(data[i].date + " 00:00:00")}
              </span>
            </div>
          );
          if (data[i].orders.length > 0) {
            for (let j = 0; j < data[i].orders.length; j++) {
              OrdersListformobile.push(
                <div
                  class="card"
                  onClick={() =>
                    navigate("/Orderdetails", {
                      state: {
                        id: data[i].orders[j].OrderId,
                        lastupdate: calLastUpdateTime(
                          data[i].orders[j].UpdateDateTimeUTC
                        ),
                        SiteId: data[i].orders[j].SiteId,
                        GpsAvailable: data[i].orders[j].GpsAvailable,
                      },
                    })
                  }
                >
                  <div class="card-body">
                    <div className="row">
                      <div className="col alignrightforupdatetime">
                        {calLastUpdateTime(data[i].orders[j].UpdateDateTimeUTC)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col tdbold marginbtm">
                        <Icon.GeoAlt />{" "}
                        {isWestcoast === "true"
                          ? data[i].orders[j].Destination
                          : data[i].orders[j].Destination +
                            "-" +
                            data[i].orders[j].SiteId}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        {" "}
                        <Icon.Clock />{" "}
                        {data[i].orders[j].EarliestDateTimeUTC
                          ? calculateETAbyTimezoneformobile(
                              data[i].orders[j].EarliestDateTimeUTC,
                              data[i].orders[j].LatestDateTimeUTC,
                              data[i].orders[j].TimeZone
                            )
                          : "No data"}
                        <p className="petaorder">
                          {data[i].orders[j].EarliestDateTimeUTC
                            ? ShowDateforEtamobile(
                                data[i].orders[j].EarliestDateTimeUTC,
                                data[i].orders[j].LatestDateTimeUTC,
                                data[i].orders[j].TimeZone
                              )
                            : ""}
                        </p>
                      </div>
                      <div className="col">
                        <Icon.Truck />{" "}
                        {data[i].orders[j].Carrier
                          ? data[i].orders[j].Carrier
                          : "No data"}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        {" "}
                        <Icon.CardList />{" "}
                        {data[i].orders[j].GpsAvailable === true ? (
                          data[i].orders[j].OrderId
                        ) : (
                          <span className="clrAmber">
                            {data[i].orders[j].OrderId}
                          </span>
                        )}
                      </div>
                      <div className="col">
                        {AddStatusIcon(data[i].orders[j].OrderStatus)}{" "}
                        {getStatusName(data[i].orders[j].OrderStatus)}
                      </div>
                    </div>
                    {isWestcoast === "true" ? (
                      <div className="row paddingtopsitename">
                        <div className="col">
                          <Icon.Truck /> {data[i].orders[j].SiteId}
                        </div>
                        <div className="col">
                          <Icon.GeoAlt />{" "}
                          {data[i].orders[j].SiteName
                            ? findArconumber(data[i].orders[j].SiteName)
                            : "No data"}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="row">
                      <div className="col paddingtopsitename">
                        <Icon.GeoAlt />
                        {data[i].orders[j].SiteName
                          ? data[i].orders[j].SiteName
                          : "No data"}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col alignright">
                        {" "}
                        {data[i].orders[j].GpsAvailable === true ? (
                          <Icon.ArrowRight />
                        ) : (
                          <Icon.ExclamationTriangleFill
                            title="No GPS"
                            color="#FFBF00"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          }
        }
      } catch (err) {
        console.log(err.message);
      }
    } else {
      OrdersListformobile.push(<span className="box">No Orders</span>);
    }
    return OrdersListformobile;
  }
  function myDate(date) {
    if (date !== null && date !== "" && date !== "undefined 00:00:00") {
      try {
        var r = "";
        var inputDate = new Date(date);
        var todayDate = new Date();
        var tomorrowDate = new Date();
        tomorrowDate.setDate(tomorrowDate.getDate() + 1);
        var yesterdayDate = new Date();
        yesterdayDate.setDate(yesterdayDate.getDate() - 1);
        var weekdays = new Array(7);
        weekdays[0] = "SUNDAY";
        weekdays[1] = "MONDAY";
        weekdays[2] = "TUESDAY";
        weekdays[3] = "WEDNESDAY";
        weekdays[4] = "THURSDAY";
        weekdays[5] = "FRIDAY";
        weekdays[6] = "SATURDAY";
        if (
          inputDate.setHours(0, 0, 0, 0) == yesterdayDate.setHours(0, 0, 0, 0)
        ) {
          r = "YESTERDAY";
        } else if (
          inputDate.setHours(0, 0, 0, 0) == todayDate.setHours(0, 0, 0, 0)
        ) {
          r = "TODAY";
        } else if (
          inputDate.setHours(0, 0, 0, 0) == tomorrowDate.setHours(0, 0, 0, 0)
        ) {
          r = "TOMORROW";
        } else {
          r = weekdays[inputDate.getDay()];
        }
        return r;
      } catch (err) {
        console.log(err.message);
      }
    }
  }

  function changeDateFormat(utcdate) {
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    if (utcdate != "" && utcdate != null && utcdate != undefined) {
      const Ldate = new Date(utcdate);

      let monthname = month[Ldate.getMonth()];

      let Dateval = Ldate.getDate();

      let yearVal = Ldate.getFullYear();

      return monthname + " " + Dateval + ", " + yearVal;
    }
  }

  const bindSelectedItems = () => {
    try {
      //document.getElementById("txtsearchm").value = " ";
      //document.getElementById("txtsearch").value = " ";
      setIsLoading(true);
      var getfiltercountval = localStorage.getItem("sessionapplyfiltercount");
      if (getfiltercountval > 1) {
        localStorage.removeItem("sessionOrderPagePrevKey");
        localStorage.removeItem("sessionOrderPageKey");
        setStack([]);
      }
      var getDestinationselectedValue =
        localStorage.getItem("sessionresultDest");
      if (
        getDestinationselectedValue != null &&
        getDestinationselectedValue != ""
      ) {
        var filterval = getDestinationselectedValue.split(",");
        filterval.pop();
      }
      var getStatusselectedValue = localStorage.getItem("sessionresultStatus");
      if (getStatusselectedValue != null && getStatusselectedValue != "") {
        var filterStatusval = getStatusselectedValue.split(",");
        filterStatusval.pop();
      }

      var getHistoryselectedValue = localStorage.getItem("sessionHistory");
      if (getHistoryselectedValue != null && getHistoryselectedValue != "") {
        var filterHistoryval = getHistoryselectedValue.split(",");
        filterHistoryval.pop();
      }
      //Call Api to get the filter data
      var sDestination = "",
        sStatusVal = "",
        sHistory = "";
      if (
        getDestinationselectedValue != undefined &&
        getDestinationselectedValue != null
      ) {
        sDestination = getDestinationselectedValue.slice(0, -1);
      }
      if (
        getStatusselectedValue != undefined &&
        getStatusselectedValue != null
      ) {
        var sStatusv = getStatusselectedValue.split(",");
        sStatusv.splice(-1);
        if (sStatusv.length > 0) {
          for (var j = 0; j < sStatusv.length; j++) {
            sStatusVal += getStatusval(sStatusv[j]);
            sStatusVal += ",";
          }
          sStatusVal = sStatusVal.replace(/,\s*$/, "");
        }
      }
      if (
        getHistoryselectedValue != undefined &&
        getHistoryselectedValue != null
      ) {
        sHistory = getHistoryselectedValue.slice(0, -1);
      }
      var apiurl = url + "/api/orders?";
      var count = 0;
      if (sDestination != undefined && sDestination != "") {
        apiurl += "Location=" + sDestination;
        count++;
      }
      if (sStatusVal != undefined && sStatusVal != "") {
        if (count == 0) {
          apiurl += "OrderStatus=" + sStatusVal;
          count++;
        } else {
          apiurl += "&OrderStatus=" + sStatusVal;
        }
      }
      if (sHistory != undefined && sHistory != "") {
        if (sHistory != "Custom") {
          var datevl = getHistoryDate(sHistory);
          if (count == 0) {
            apiurl += "FromDate=" + datevl[0] + "&ToDate=" + datevl[1];
          } else {
            apiurl += "&FromDate=" + datevl[0] + "&ToDate=" + datevl[1];
          }
        } else {
          var CustomDateValue = localStorage.getItem("sessionCustomHistory");
          if (CustomDateValue != null) {
            var CustomDateRangeval = CustomDateValue.split("-");
            if (count == 0) {
              apiurl +=
                "FromDate=" +
                CustomDateRangeval[0] +
                "-" +
                CustomDateRangeval[1] +
                "-" +
                CustomDateRangeval[2] +
                "&ToDate=" +
                CustomDateRangeval[3].replace(/\s+/g, "") +
                "-" +
                CustomDateRangeval[4] +
                "-" +
                CustomDateRangeval[5];
            } else {
              apiurl +=
                "&FromDate=" +
                CustomDateRangeval[0] +
                "-" +
                CustomDateRangeval[1] +
                "-" +
                CustomDateRangeval[2] +
                "&ToDate=" +
                CustomDateRangeval[3].replace(/\s+/g, "") +
                "-" +
                CustomDateRangeval[4] +
                "-" +
                CustomDateRangeval[5];
            }
          }
          var pageKey = localStorage.getItem("sessionOrderPageKey");
          if (pageKey != null) {
            apiurl += "&nextkey=" + pageKey;
          }
        }
      }
      //console.log(apiurl);
      //remove text from filter textbox
      //var desktopSearch = document.getElementById("txtsearch");
      //var mobileSearch = document.getElementById("txtsearchm");
      // if (desktopSearch) {
      //   document.getElementById("txtsearch").value = "";
      // }
      // if (mobileSearch) {
      //   document.getElementById("txtsearchm").value = "";
      // }
      var divId = document.getElementById("divsetdestname");
      if (divId != null && divId != undefined) {
        setCount(1);
        setfilterUrl(apiurl);
        localStorage.setItem("sessionfilterurl", apiurl);
        axios
          .get(apiurl)
          .then(function (res) {
            setData(res.data);
            setMasterData(res.data);
            setPost(res.data);
            setIsLoading(false);
            // renderOrders();
            // renderOrdersformobile();
            setTotalDataCount(res.headers.totalrecords);
            setTotalDataCountcheck(res.headers.totalrecords);
            binddatacount(res.headers.totalrecords);
            // setIswestcoast(res.headers.iswestcoast);
            if (res.headers.nextkey != null && res.headers.nextkey != "") {
              setShowNext(false);
              setNextKeyval(res.headers.nextkey);
            } else {
              setShowNext(true);
            }
            //getcheckedData();
          })
          .catch(function (err) {
            if (err.response.status === 401) {
              window.location = "/Login?error=" + err.response.data.message;
            } else if (err.response.status === 403) {
              window.location =
                "/Login?error=" +
                "Your token is expired, please login from bpc.";
            } else {
              window.location = "/Error?error=" + err.message;
            }
          });
        document.getElementById("divsetdestname").innerHTML = "";
        if (filterval != undefined && filterval.length > 0) {
          for (var j = 0; j < filterval.length; j++) {
            var element = document.createElement("button");
            let selected = filterval[j];
            element.type = "button";
            element.id = filterval[j];
            element.value = filterval[j];
            element.name = "Location";
            element.className = "btn btn-secondary btn-sm";
            element.style =
              "border-radius:12px; margin-right:10px;margin-bottom: 10px;";
            element.innerHTML = filterval[j];
            var spantag = document.createElement("span");
            spantag.innerHTML = '<span aria-hidden="true"> &times;</span>';
            spantag.onclick = function () {
              CloseSelectedButton(selected, element.name);
            };
            spantag.style = "cursor: pointer";
            element.appendChild(spantag);
            divId.appendChild(element);
          }
        }
        if (filterStatusval != undefined && filterStatusval.length > 0) {
          for (var k = 0; k < filterStatusval.length; k++) {
            var btnStatus = document.createElement("button");
            let selected = filterStatusval[k];
            btnStatus.type = "button";
            btnStatus.id = filterStatusval[k];
            btnStatus.value = filterStatusval[k];
            btnStatus.name = "Status";
            btnStatus.className = "btn btn-secondary btn-sm";
            btnStatus.style =
              "border-radius:12px; margin-right:10px;margin-bottom: 10px;";
            btnStatus.innerHTML = filterStatusval[k];
            var spantag = document.createElement("span");
            spantag.innerHTML = '<span aria-hidden="true"> &times;</span>';
            spantag.onclick = function () {
              CloseSelectedButton(selected, btnStatus.name);
            };
            spantag.style = "cursor: pointer";
            btnStatus.appendChild(spantag);

            divId.appendChild(btnStatus);
          }
        }

        if (filterHistoryval != undefined && filterHistoryval.length > 0) {
          for (var p = 0; p < filterHistoryval.length; p++) {
            var btnHistory = document.createElement("button");
            let selected = filterHistoryval[p];
            btnHistory.type = "button";
            btnHistory.id = filterHistoryval[p];
            btnHistory.value = filterHistoryval[p];
            btnHistory.name = "History";
            btnHistory.className = "btn btn-secondary btn-sm";
            btnHistory.style =
              "border-radius:12px; margin-right:10px;margin-bottom: 10px;";
            btnHistory.innerHTML = filterHistoryval[p];
            var spantag = document.createElement("span");
            spantag.innerHTML = '<span aria-hidden="true"> &times;</span>';
            spantag.onclick = function () {
              CloseSelectedButton(selected, btnHistory.name);
            };
            spantag.style = "cursor: pointer";
            btnHistory.appendChild(spantag);

            divId.appendChild(btnHistory);
          }
        }
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      checkbtnPrevstatus();
      var chktxtsearchval = localStorage.getItem("sessiontxtsearchval");
      if (
        chktxtsearchval !== null &&
        chktxtsearchval !== "" &&
        chktxtsearchval !== undefined
      ) {
        handletextChange();
      }

      // setTimeout(function () {
      //   //commented below line for customized view
      //   //getcheckedData();
      //    //Filter the data by using search text box value.
      //    handletextChange();
      //   setIsLoading(false);
      // }, 1000);
    }
  };
  const getcheckedData = () => {
    try {
      setIsLoading(true);
      var getSettingsvalue;
      getSettingsvalue = localStorage.getItem("sessioncheckedSettings");
      if (
        getSettingsvalue === null ||
        getSettingsvalue === "" ||
        getSettingsvalue === undefined
      ) {
        getSettingsvalue = userSettingData.resultSettings;
      }
      if (
        getSettingsvalue != null &&
        getSettingsvalue != "" &&
        getSettingsvalue != undefined
      ) {
        var getSettingsvalue = getSettingsvalue.split(",");
        getSettingsvalue.pop();
        if (
          getSettingsvalue != "" &&
          getSettingsvalue != "" &&
          getSettingsvalue.length > 0
        ) {
          var thDest = document.getElementsByClassName("DestHead");
          var tdDest = document.getElementsByClassName("Dest_col");

          var thSiteNumber = document.getElementsByClassName("SiteNumberHead");
          var tdSiteNumber = document.getElementsByClassName("SiteNumber_col");

          var thEta = document.getElementsByClassName("EtaHead");
          var tdEta = document.getElementsByClassName("Eta_col");

          var thCarrier = document.getElementsByClassName("CarrierHead");
          var tdCarrier = document.getElementsByClassName("Carrier_col");

          var thSiteName = document.getElementsByClassName("SiteNameHead");
          var tdSiteName = document.getElementsByClassName("SiteName_col");

          var thArcoNumber = document.getElementsByClassName("ArcoHead");
          var tdArcoNumber = document.getElementsByClassName("ArcoNumber_col");

          var thOrder = document.getElementsByClassName("OrderHead");
          var tdOrder = document.getElementsByClassName("Order_col");

          var thStatus = document.getElementsByClassName("StatusHead");
          var tdStatus = document.getElementsByClassName("Status_col");

          var thUpdated = document.getElementsByClassName("UpdatedHead");
          var tdUpdated = document.getElementsByClassName("Updated_col");

          if (isWestcoast === "true") {
            for (var i = 0; i < getSettingsvalue.length; i++) {
              if (getSettingsvalue[0] === "true") {
                if (tdDest.length > 0) {
                  for (var j = 0; j < tdDest.length; ++j) {
                    tdDest[j].style.display = "revert";
                  }
                }
                thDest[0].style.display = "revert";
              } else {
                for (var j = 0; j < tdDest.length; ++j) {
                  tdDest[j].style.display = "none";
                }
                thDest[0].style.display = "none";
              }
              if (getSettingsvalue[1] === "true") {
                if (tdSiteNumber.length > 0) {
                  for (var j = 0; j < tdSiteNumber.length; ++j) {
                    tdSiteNumber[j].style.display = "revert";
                  }
                }
                thSiteNumber[0].style.display = "revert";
              } else {
                for (var j = 0; j < tdSiteNumber.length; ++j) {
                  tdSiteNumber[j].style.display = "none";
                }
                thSiteNumber[0].style.display = "none";
              }
              if (getSettingsvalue[2] === "true") {
                if (tdEta.length > 0) {
                  for (var j = 0; j < tdEta.length; ++j) {
                    tdEta[j].style.display = "revert";
                  }
                }
                thEta[0].style.display = "revert";
              } else {
                for (var j = 0; j < tdEta.length; ++j) {
                  tdEta[j].style.display = "none";
                }
                thEta[0].style.display = "none";
              }
              if (getSettingsvalue[3] === "true") {
                if (tdCarrier.length > 0) {
                  for (var j = 0; j < tdCarrier.length; ++j) {
                    tdCarrier[j].style.display = "revert";
                  }
                }
                thCarrier[0].style.display = "revert";
              } else {
                for (var j = 0; j < tdEta.length; ++j) {
                  tdCarrier[j].style.display = "none";
                }
                thCarrier[0].style.display = "none";
              }
              if (getSettingsvalue[4] === "true") {
                if (tdSiteName.length > 0) {
                  for (var j = 0; j < tdSiteName.length; ++j) {
                    tdSiteName[j].style.display = "revert";
                  }
                }
                thSiteName[0].style.display = "revert";
              } else {
                for (var j = 0; j < tdEta.length; ++j) {
                  tdSiteName[j].style.display = "none";
                }
                thSiteName[0].style.display = "none";
              }
              if (getSettingsvalue[5] === "true") {
                if (tdArcoNumber.length > 0) {
                  for (var j = 0; j < tdArcoNumber.length; ++j) {
                    tdArcoNumber[j].style.display = "revert";
                  }
                }
                thArcoNumber[0].style.display = "revert";
              } else {
                for (var j = 0; j < tdEta.length; ++j) {
                  tdArcoNumber[j].style.display = "none";
                }
                thArcoNumber[0].style.display = "none";
              }

              if (getSettingsvalue[6] === "true") {
                if (tdOrder.length > 0) {
                  for (var j = 0; j < tdOrder.length; ++j) {
                    tdOrder[j].style.display = "revert";
                  }
                }
                thOrder[0].style.display = "revert";
              } else {
                for (var j = 0; j < tdEta.length; ++j) {
                  tdOrder[j].style.display = "none";
                }
                thOrder[0].style.display = "none";
              }
              if (getSettingsvalue[7] === "true") {
                if (tdStatus.length > 0) {
                  for (var j = 0; j < tdStatus.length; ++j) {
                    tdStatus[j].style.display = "revert";
                  }
                }
                thStatus[0].style.display = "revert";
              } else {
                for (var j = 0; j < tdEta.length; ++j) {
                  tdStatus[j].style.display = "none";
                }
                thStatus[0].style.display = "none";
              }
              if (getSettingsvalue[8] === "true") {
                if (tdUpdated.length > 0) {
                  for (var j = 0; j < tdUpdated.length; ++j) {
                    tdUpdated[j].style.display = "revert";
                  }
                }
                thUpdated[0].style.display = "revert";
              } else {
                for (var j = 0; j < tdEta.length; ++j) {
                  tdUpdated[j].style.display = "none";
                }
                thUpdated[0].style.display = "none";
              }
            }
          } else {
            for (var i = 0; i < getSettingsvalue.length; i++) {
              if (getSettingsvalue[0] === "true") {
                if (tdDest.length > 0) {
                  for (var j = 0; j < tdDest.length; ++j) {
                    tdDest[j].style.display = "revert";
                  }
                }
                thDest[0].style.display = "revert";
              } else {
                thDest[0].style.display = "none";
                for (var j = 0; j < tdDest.length; ++j) {
                  tdDest[j].style.display = "none";
                }
              }

              if (getSettingsvalue[1] === "true") {
                thEta[0].style.display = "revert";
                if (tdEta.length > 0) {
                  for (var j = 0; j < tdEta.length; ++j) {
                    tdEta[j].style.display = "revert";
                  }
                }
              } else {
                thEta[0].style.display = "none";
                for (var j = 0; j < tdEta.length; ++j) {
                  tdEta[j].style.display = "none";
                }
              }
              if (getSettingsvalue[2] === "true") {
                thCarrier[0].style.display = "revert";
                if (tdCarrier.length > 0) {
                  for (var j = 0; j < tdCarrier.length; ++j) {
                    tdCarrier[j].style.display = "revert";
                  }
                }
              } else {
                thCarrier[0].style.display = "none";
                for (var j = 0; j < tdEta.length; ++j) {
                  tdCarrier[j].style.display = "none";
                }
              }
              if (getSettingsvalue[3] === "true") {
                thSiteName[0].style.display = "revert";
                if (tdSiteName.length > 0) {
                  for (var j = 0; j < tdSiteName.length; ++j) {
                    tdSiteName[j].style.display = "revert";
                  }
                }
              } else {
                thSiteName[0].style.display = "none";
                for (var j = 0; j < tdEta.length; ++j) {
                  tdSiteName[j].style.display = "none";
                }
              }

              if (getSettingsvalue[4] === "true") {
                thOrder[0].style.display = "revert";
                if (tdOrder.length > 0) {
                  for (var j = 0; j < tdOrder.length; ++j) {
                    tdOrder[j].style.display = "revert";
                  }
                }
              } else {
                thOrder[0].style.display = "none";
                for (var j = 0; j < tdEta.length; ++j) {
                  tdOrder[j].style.display = "none";
                }
              }
              if (getSettingsvalue[5] === "true") {
                thStatus[0].style.display = "revert";
                if (tdStatus.length > 0) {
                  for (var j = 0; j < tdStatus.length; ++j) {
                    tdStatus[j].style.display = "revert";
                  }
                }
              } else {
                thStatus[0].style.display = "none";
                for (var j = 0; j < tdEta.length; ++j) {
                  tdStatus[j].style.display = "none";
                }
              }
              if (getSettingsvalue[6] === "true") {
                thUpdated[0].style.display = "revert";
                if (tdUpdated.length > 0) {
                  for (var j = 0; j < tdUpdated.length; ++j) {
                    tdUpdated[j].style.display = "revert";
                  }
                }
              } else {
                thUpdated[0].style.display = "none";
                for (var j = 0; j < tdEta.length; ++j) {
                  tdUpdated[j].style.display = "none";
                }
              }
            }
          }
        }
      }
    } catch (err) {
      //console.log(err.message);
    } finally {
      setTimeout(function () {
        setIsLoading(false);
      }, 1000);
    }
  };
  const CloseSelectedButton = (Id, name) => {
    try {
      //document.getElementById("txtsearchm").value = "";
      //document.getElementById("txtsearch").value = "";
      setIsLoading(true);
      var btnremove = document.getElementById(Id);
      btnremove.remove();
      var resultDest = [];
      var resultStatus = [];
      if (name == "History") {
        localStorage.removeItem("sessionHistory");
        localStorage.removeItem("sessionCustomHistory");
        localStorage.removeItem("sessionOrderPagePrevKey");
        localStorage.removeItem("sessionOrderPageKey");
        localStorage.removeItem("sessionapplyfiltercount");
        setStack([]);
      }
      if (name == "Location") {
        const selectedValue = localStorage.getItem("sessionresultDest");
        var filterval = selectedValue.split(",");
        filterval.splice(-1);
        if (filterval.length > 0) {
          for (var i = 0; i < filterval.length; i++) {
            if (filterval[i] != Id) {
              resultDest += filterval[i] + ",";
            }
          }
        }
        localStorage.removeItem("sessionresultDest");
        localStorage.setItem("sessionresultDest", resultDest);
        localStorage.removeItem("sessionOrderPagePrevKey");
        localStorage.removeItem("sessionOrderPageKey");
        localStorage.removeItem("sessionapplyfiltercount");
        setStack([]);
      }
      if (name == "Status") {
        const selectedStatus = localStorage.getItem("sessionresultStatus");
        var filterStatus = selectedStatus.split(",");
        filterStatus.splice(-1);
        if (filterStatus.length > 0) {
          for (var j = 0; j < filterStatus.length; j++) {
            if (filterStatus[j] != Id) {
              resultStatus += filterStatus[j] + ",";
            }
          }
        }
        localStorage.removeItem("sessionresultStatus");
        localStorage.setItem("sessionresultStatus", resultStatus);
        localStorage.removeItem("sessionOrderPagePrevKey");
        localStorage.removeItem("sessionOrderPageKey");
        localStorage.removeItem("sessionapplyfiltercount");
        setStack([]);
      }

      var urlOrder = url + "/api/orders?";
      var selecteddest = localStorage.getItem("sessionresultDest");
      var selectOrderStatus = localStorage.getItem("sessionresultStatus");
      var selectedHistory = localStorage.getItem("sessionHistory");
      if (
        selecteddest != undefined &&
        selecteddest != null &&
        selecteddest != ""
      ) {
        var sDestremoved = selecteddest.slice(0, -1);
      }
      if (
        selectOrderStatus != undefined &&
        selectOrderStatus != null &&
        selectOrderStatus != ""
      ) {
        var sStatusremoved = [];
        var selectedStatusafterRemoved = selectOrderStatus.slice(0, -1);
        if (selectedStatusafterRemoved != null) {
          var sremovedStatus = selectedStatusafterRemoved.split(",");
          for (var j = 0; j < sremovedStatus.length; j++) {
            sStatusremoved += getStatusval(sremovedStatus[j]) + ",";
          }
          sStatusremoved = sStatusremoved.replace(/,\s*$/, "");
        }
      }
      if (
        selectedHistory != undefined &&
        selectedHistory != null &&
        selectedHistory != ""
      ) {
      }
      var countr = 0;
      if (sDestremoved != undefined && sDestremoved != "") {
        urlOrder += "Location=" + sDestremoved;
        countr++;
      }
      if (sStatusremoved != undefined && sStatusremoved != "") {
        if (countr == 0) {
          urlOrder += "OrderStatus=" + sStatusremoved;
          countr++;
        } else {
          urlOrder += "&OrderStatus=" + sStatusremoved;
        }
      }
      if (selectedHistory != undefined && selectedHistory != "") {
        if (selectedHistory != "Custom,") {
          var Historytext = selectedHistory.slice(0, -1);
          var datevl = getHistoryDate(Historytext);
          if (countr == 0) {
            urlOrder += "FromDate=" + datevl[0] + "&ToDate=" + datevl[1];
          } else {
            urlOrder += "&FromDate=" + datevl[0] + "&ToDate=" + datevl[1];
          }
        } else {
          var CustomDateVal = localStorage.getItem("sessionCustomHistory");
          var CustomDateRangeval = CustomDateVal.split("-");
          if (CustomDateRangeval != null) {
            urlOrder +=
              "FromDate=" +
              CustomDateRangeval[0] +
              "-" +
              CustomDateRangeval[1] +
              "-" +
              CustomDateRangeval[2] +
              "&ToDate=" +
              CustomDateRangeval[3].replace(/\s+/g, "") +
              "-" +
              CustomDateRangeval[4] +
              "-" +
              CustomDateRangeval[5];
          }
          if (countr == 0) {
            //   url += "FromDate=" + "" + "&ToDate=" + "";
            // } else {
            //   url += "&FromDate=" + "" + "&ToDate=" + "";
            // Not required to pass any parameter in url.
          }
        }
      }
      setfilterUrl(urlOrder);
      localStorage.setItem("sessionfilterurl", urlOrder);
      axios
        .get(urlOrder)
        .then(function (res) {
          setData(res.data);
          setMasterData(res.data);
          setPost(res.data);
          setTotalDataCount(res.headers.totalrecords);
          setTotalDataCountcheck(res.headers.totalrecords);
          binddatacount(res.headers.totalrecords);
          //setIswestcoast(res.headers.iswestcoast);
          if (res.headers.nextkey != null && res.headers.nextkey != "") {
            setShowNext(false);
            setNextKeyval(res.headers.nextkey);
          } else {
            setShowNext(true);
          }
          setShowPrev(true);
        })
        .catch(function (err) {
          if (err.response.status === 401) {
            window.location = "/Login?error=" + err.response.data.message;
          } else if (err.response.status === 403) {
            window.location =
              "/Login?error=" + "Your token is expired, please login from bpc.";
          } else {
            window.location = "/Error?error=" + err.message;
          }
        });
    } catch (err) {
      //console.log(err.message);
    } finally {
      var chksessionval = localStorage.getItem("sessiontxtsearchval");
      if (
        chksessionval !== null &&
        chksessionval !== "" &&
        chksessionval !== undefined
      ) {
        handletextChange();
      }
      setTimeout(function () {
        //getcheckedData();
        //Filter the data by using search text box value.
        setIsLoading(false);
      }, 1000);
    }
  };
  function getHistoryDate(Datetext) {
    var FromDate = "";
    var ToDate = "";
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    try {
      if (Datetext == "Today") {
        FromDate = today;
        ToDate = today;
      } else if (Datetext == "Yesterday") {
        FromDate = GetPreviousDate(-1);
        ToDate = GetPreviousDate(-1);
      } else if (Datetext == "Last 7 Days") {
        FromDate = GetPreviousDate(-7);
        ToDate = today;
      } else if (Datetext == "Last 30 Days") {
        FromDate = GetPreviousDate(-30);
        ToDate = today;
      }
    } catch (err) {
      console.log(err.message);
    }

    return [FromDate, ToDate];
  }
  function GetPreviousDate(prvalue) {
    var prdate = "";
    try {
      if (prvalue != "" && prvalue != null) {
        var d = new Date();
        if (prvalue == "-1") {
          d.setDate(d.getDate() - 1);
          prdate = FormatDate(d);
        } else if (prvalue == "-7") {
          d.setDate(d.getDate() - 7);
          prdate = FormatDate(d);
        } else if (prvalue == "-30") {
          d.setDate(d.getDate() - 30);
          prdate = FormatDate(d);
        }
        return prdate;
      }
    } catch (err) {
      console.log(err.message);
    }
  }
  function FormatDate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    date = yyyy + "-" + mm + "-" + dd;
    return date;
  }
  // Removed UI side Search textbox Filter
  // function handletextChange(event) {
  //   try {
  //     setIsLoading(true);
  //     var textvalue;
  //     var filteredData = [];
  //     if (event != undefined) {
  //       textvalue = event.target.value;
  //       textvalue = textvalue.toUpperCase();
  //     } else {
  //       textvalue = document.getElementById("txtsearchm").value;
  //       textvalue = textvalue.toUpperCase();
  //     }
  //     var countdata = 0;
  //     if (textvalue != "" && textvalue != null && textvalue != undefined) {
  //       if (masterData.length > 0) {
  //         masterData.forEach((element) => {
  //           var filterdaysOrders = element.orders.filter(
  //             (x) =>
  //               x.Destination?.toUpperCase().includes(textvalue) ||
  //               x.OrderId?.toString().includes(textvalue) ||
  //               x.Carrier?.toUpperCase().includes(textvalue) ||
  //               x.SiteName?.toUpperCase().includes(textvalue) ||
  //               x.SiteId?.toString().includes(textvalue)
  //           );
  //           if (filterdaysOrders.length > 0) {
  //             filteredData.push({
  //               date: element.date,
  //               orders: filterdaysOrders,
  //             });
  //           }
  //         });
  //       }
  //       setData(filteredData);
  //       if(filteredData.length > 0)
  //       {
  //          for(var i = 0; i < filteredData.length; i++)
  //          {
  //             countdata = countdata + filteredData[i].orders.length;
  //          }
  //       }
  //       setTotalDataCount(countdata)
  //       binddatacount(countdata, 1)
  //       // renderOrders();
  //       // renderOrdersformobile();
  //     } else {
  //       setData(masterData);
  //       if(nextkeyval != "" && nextkeyval != null)
  //       {
  //         setShowNext(false);
  //       }
  //       // if(masterData.length > 0)
  //       // {
  //       //    for(var i = 0; i < masterData.length; i++)
  //       //    {
  //       //     countdata = countdata + masterData[i].orders.length;
  //       //    }
  //       // }
  //       setTotalDataCount(totalDataCountcheck)
  //       binddatacount(totalDataCountcheck)
  //       // renderOrders();
  //       // renderOrdersformobile();
  //     }
  //   } catch (err) {
  //     console.log(err.message);
  //   } finally {
  //     setTimeout(function () {
  //       setIsLoading(false);
  //     }, 1000);
  //   }
  // }
  // function checkTime(i) {
  //   if (i < 10) {
  //     i = "0" + i;
  //   }
  //   return i;
  // }

  // function etaDate(EarliestDate, LatestDate) {
  //   if (
  //     EarliestDate != null &&
  //     LatestDate != null &&
  //     EarliestDate != "" &&
  //     LatestDate != ""
  //   ) {
  //     try {
  //       var startDate = new Date(EarliestDate);
  //       var endDate = new Date(LatestDate);
  //       var startdd = String(startDate.getDate()).padStart(2, "0");
  //       var startmm = String(startDate.getMonth() + 1);
  //       var startHH = String(startDate.getUTCHours());
  //       var startminute = String(startDate.getUTCMinutes());
  //       startdd = checkTime(startdd);
  //       startmm = checkTime(startmm);
  //       startHH = checkTime(startHH);
  //       startminute = checkTime(startminute);

  //       var enddd = String(endDate.getDate()).padStart(2, "0");
  //       var endmm = String(endDate.getMonth() + 1);
  //       var endHH = String(endDate.getUTCHours());
  //       var endminute = String(endDate.getUTCMinutes());
  //       enddd = checkTime(enddd);
  //       endmm = checkTime(endmm);
  //       endHH = checkTime(endHH);
  //       endminute = checkTime(endminute);
  //       return (
  //         startmm +
  //         "-" +
  //         startdd +
  //         " " +
  //         startHH +
  //         ":" +
  //         startminute +
  //         " - " +
  //         endmm +
  //         "-" +
  //         enddd +
  //         " " +
  //         endHH +
  //         ":" +
  //         endminute
  //       );
  //     } catch (err) {
  //       console.log(err.message);
  //     }
  //   }
  // }

  function handletextChange(event) {
    try {
      setTimeout(function () {
        var getsearchvalue = localStorage.getItem("sessiontxtsearchval");
        var textvalue;
        if (event !== undefined) {
          textvalue = event.target.value;
        } else if (
          getsearchvalue != null &&
          getsearchvalue != "" &&
          getsearchvalue != undefined
        ) {
          document.getElementById("txtsearch").value = getsearchvalue;
          textvalue = getsearchvalue;
        } else {
          var txtsearch = document.getElementById("txtsearchm");
          if (txtsearch) {
            textvalue = document.getElementById("txtsearchm").value;
          }
        }
        if (textvalue !== "" && textvalue !== null && textvalue !== undefined) {
          if (textvalue.length > 3) {
            localStorage.setItem("sessiontxtsearchval", textvalue);
            setIsLoading(true);
            var sessionfilterurl = localStorage.getItem("sessionfilterurl");
            var currenturl;
            if (
              sessionfilterurl !== "" &&
              sessionfilterurl !== null &&
              sessionfilterurl !== undefined
            ) {
              currenturl = localStorage.getItem("sessionfilterurl");
            } else {
              currenturl = filterUrl;
            }
            axios
              .get(currenturl + "&SearchQuery=" + textvalue)
              .then(function (res) {
                setTimeout(function () {
                  setData(res.data);
                  setMasterData(res.data);
                  setIsLoading(false);
                }, 1000);
                setCurrentUser(res.data);
                setTotalDataCount(res.headers.totalrecords);
                setTotalDataCountcheck(res.headers.totalrecords);
                setPost(res);
                binddatacount(res.headers.totalrecords);
                // setIswestcoast(res.headers.iswestcoast);
                if (res.headers.nextkey != null && res.headers.nextkey != "") {
                  setShowNext(false);
                  setNextKeyval(res.headers.nextkey);
                } else {
                  setShowNext(true);
                }
              })
              .catch(function (err) {
                if (err.response.status === 401) {
                  window.location = "/Login?error=" + err.response.data.message;
                } else if (err.response.status === 403) {
                  window.location =
                    "/Login?error=" +
                    "Your token is expired, please login from bpc.";
                } else {
                  window.location = "/Error?error=" + err.message;
                }
              });
          }
        } else if (textvalue.length == 0) {
          localStorage.removeItem("sessiontxtsearchval");
          setIsLoading(true);
          axios
            .get(filterUrl)
            .then(function (res) {
              setTimeout(function () {
                setData(res.data);
                setMasterData(res.data);
                setIsLoading(false);
              }, 1000);
              setCurrentUser(res.data);
              setTotalDataCount(res.headers.totalrecords);
              setTotalDataCountcheck(res.headers.totalrecords);
              setPost(res);
              binddatacount(res.headers.totalrecords);
              //setIswestcoast(res.headers.iswestcoast);
              if (res.headers.nextkey != null && res.headers.nextkey != "") {
                setShowNext(false);
                setNextKeyval(res.headers.nextkey);
              } else {
                setShowNext(true);
              }
              //getcheckedData();
            })
            .catch(function (err) {
              if (err.response.status === 401) {
                window.location = "/Login?error=" + err.response.data.message;
              } else if (err.response.status === 403) {
                window.location =
                  "/Login?error=" +
                  "Your token is expired, please login from bpc.";
              } else {
                window.location = "/Error?error=" + err.message;
              }
            });
        }
        setShowPrev(true);
        localStorage.removeItem("sessionapplyfiltercount");
        localStorage.removeItem("sessionOrderPageKey");
        localStorage.removeItem("sessionOrderPagePrevKey");
      }, 1000);
    } catch (err) {
      console.log(err.message);
    }
  }

  function submitNextPage() {
    localStorage.removeItem("sessionOrderPageKey");
    localStorage.removeItem("sessionOrderPagePrevKey");
    localStorage.setItem("sessionOrderPageKey", nextkeyval);
    setStackCount(stackCount + 1);
    setShowPrev(false);
    //below session for check filter count.
    var getfiltercount = localStorage.getItem("sessionapplyfiltercount");
    if (getfiltercount == null) {
      getfiltercount = 0;
    }
    localStorage.setItem("sessionapplyfiltercount", getfiltercount + 1);
    try {
      var postUrl;
      var sessionfilterurl = localStorage.getItem("sessionfilterurl").toString();
      if (
        sessionfilterurl !== "" &&
        sessionfilterurl !== null &&
        sessionfilterurl !== undefined
      ) {
        postUrl = localStorage.getItem("sessionfilterurl").toString();
      } else {
        postUrl = filterUrl;
      }
       var getsearchboxvalue = localStorage.getItem("sessiontxtsearchval");
     if (
          getsearchboxvalue != null &&
          getsearchboxvalue != "" &&
          getsearchboxvalue != undefined
        ) {
            postUrl = postUrl + "&SearchQuery=" + getsearchboxvalue;
        }
      if (postUrl != null && postUrl != "" && postUrl != undefined) {
        var aftersymbolurl = postUrl.substr(
          0,
          postUrl.lastIndexOf("&") + 8
        );
        var lastseven = aftersymbolurl.substr(aftersymbolurl.length - 7);
        if (lastseven == "nextkey") {
          postUrl = aftersymbolurl + "=" + nextkeyval;
        } else {
          postUrl += "&nextkey=" + nextkeyval;
        }
      } else {
        postUrl = url + "/api/orders?" + "nextkey=" + nextkeyval;
      }
      setIsLoading(true);
      axios
        .get(postUrl)
        .then(function (res) {
          setData(res.data);
          setMasterData(res.data);
          setCurrentUser(res.data);
          setPost(res);
          setTotalDataCount(res.headers.totalrecords);
          setTotalDataCountcheck(res.headers.totalrecords);
          // setIswestcoast(res.headers.iswestcoast);
          var currentstackvalue = stack;
          // setStack((stack) => stack.concat(nextkeyval));
          var newstack = currentstackvalue.concat(nextkeyval);
          setStack(newstack);
          var currentStack = stack;
          if (currentStack.length == 0) {
            localStorage.setItem("sessionOrderPagePrevKey", nextkeyval);
          } else {
            localStorage.setItem("sessionOrderPagePrevKey", newstack);
          }
          if (res.headers.nextkey != null && res.headers.nextkey != "") {
            setShowNext(false);
            setNextKeyval(res.headers.nextkey);
          } else {
            setShowNext(true);
          }
          binddatacount(res.headers.totalrecords);
          //getcheckedData();
        })
        .catch(function (err) {
          if (err.response.status === 401) {
            window.location = "/Login?error=" + err.response.data.message;
          } else if (err.response.status === 403) {
            window.location =
              "/Login?error=" + "Your token is expired, please login from bpc.";
          } else {
            window.location = "/Error?error=" + err.message;
          }
        });
    } catch (err) {
      console.log(err.message);
    } finally {
      setTimeout(function () {
        // getcheckedData();
        setIsLoading(false);
      }, 1000);
    }
  }
  function submitPrevPage() {
    try {
      if (stack != null && stack != "" && stack != undefined) {
        var listlength = stack.length;
        if (listlength > 0) {
          var currentList = stack;
          currentList.pop();
          setStack(currentList);
          localStorage.removeItem("sessionOrderPageKey");
          localStorage.removeItem("sessionOrderPagePrevKey");
          localStorage.setItem("sessionOrderPageKey", currentList);
          localStorage.setItem("sessionOrderPagePrevKey", currentList);
          setStackCount(stackCount + 1);
          var currentLength = stack.length;
          var postUrl;
          try {
            var sessionfilterurl = localStorage.getItem("sessionfilterurl");
            if (
              sessionfilterurl !== "" &&
              sessionfilterurl !== null &&
              sessionfilterurl !== undefined
            ) {
              postUrl = localStorage.getItem("sessionfilterurl").toString();
            } else {
              postUrl = filterUrl;
            }
            if (
              postUrl != null &&
              postUrl != "" &&
              postUrl != undefined &&
              currentLength > 0
            ) {
              var aftersymbol = postUrl.substr(0, postUrl.lastIndexOf("&") + 8);
              var lastseven = aftersymbol.substr(aftersymbol.length - 7);
              if (lastseven == "nextkey") {
                postUrl = aftersymbol + "=" + stack[currentLength - 1];
              } else {
                postUrl += "&nextkey=" + stack[currentLength - 1];
              }

              if (currentLength == 0) {
                setShowPrev(true);
              }
            } else {
              var aftersymbol = postUrl.substr(0, postUrl.lastIndexOf("&") + 8);
              var lastseven = aftersymbol.substr(aftersymbol.length - 7);
              if (lastseven == "nextkey") {
                var furl = postUrl.substr(0, postUrl.lastIndexOf("&"));
                postUrl = furl;
              } else {
                postUrl = filterUrl;
              }
              setShowPrev(true);
            }
             var getsearchboxvalue = localStorage.getItem("sessiontxtsearchval");
     if (
          getsearchboxvalue != null &&
          getsearchboxvalue != "" &&
          getsearchboxvalue != undefined
        ) {
            postUrl = postUrl + "&SearchQuery=" + getsearchboxvalue;
        }
            setIsLoading(true);
            axios
              .get(postUrl)
              .then(function (res) {
                setData(res.data);
                setMasterData(res.data);
                setPost(res);
                setTotalDataCount(res.headers.totalrecords);
                setTotalDataCountcheck(res.headers.totalrecords);
                binddatacount(res.headers.totalrecords);
                //setIswestcoast(res.headers.iswestcoast);
                if (res.headers.nextkey != null && res.headers.nextkey != "") {
                  setShowNext(false);
                  setNextKeyval(res.headers.nextkey);
                } else {
                  setShowNext(true);
                }
                // getcheckedData();
              })
              .catch(function (err) {
                if (err.response.status === 401) {
                  window.location = "/Login?error=" + err.response.data.message;
                } else if (err.response.status === 403) {
                  window.location =
                    "/Login?error=" +
                    "Your token is expired, please login from bpc.";
                } else {
                  window.location = "/Error?error=" + err.message;
                }
              });
          } catch (err) {
            console.log(err.message);
          }
        }
      } else {
        setShowPrev(true);
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      setTimeout(function () {
        // getcheckedData();
        setIsLoading(false);
      }, 1000);
    }
  }

  const navigate = useNavigate();
  return (
    <div>
      {currentUser ? (
        <div className="divOrder">
          <div className="row background">
            <div className="col-6">
              <p className="Orders">Orders</p>
              <div id="divsetdestname" className="chipmargin"></div>
            </div>
            <div className="col-3 desktop">
              <input
                type="text"
                className="form-control has-search"
                placeholder="Type at least 4 chars to search"
                minLength={3}
                onChange={handletextChange}
                id="txtsearch"
              />
            </div>
            <div className="col divFilterbtnpadding desktop">
              {/* <button
                type="button"
                className="btn btn-outline-success btn-sm btnmarginright"
                onClick={() => setShowColumnFilter(true)}
              >
                <Icon.Gear /> Setting
              </button> */}
              <button
                type="button"
                className="btn btn-outline-success btn-sm btnmarginright"
                onClick={() => setShowmodal(true)}
              >
                <Icon.ClockHistory /> History
              </button>
              <button
                type="button"
                id="btnFilter"
                className="btn btn-outline-success btn-sm"
                onClick={() => setShow(true)}
              >
                {" "}
                <Icon.Funnel /> Filter
                <span id="countFilter" aria-hidden="true"></span>
              </button>
            </div>
            <div className="col divFilterbtnpaddingm mobile">
              <Icon.ClockHistory
                className="mobileHistoryicon"
                onClick={() => setShowmodal(true)}
              />{" "}
              <Icon.Funnel
                className="mobilefiltericon"
                onClick={() => setShow(true)}
              />
            </div>
          </div>
          <div className="divOrdersTable desktop">
            {isLoading && (
              <div className="loader">
                <Spinner color="white" />
              </div>
            )}
            <table className="table" id="tblOrders">
              <thead className="bgcolor">
                <tr>
                  <th className="tdbold DestHead">
                    <span className="box bgcolor"></span> <Icon.GeoAlt />{" "}
                    {/* {isWestcoast === "true" ? "Site Address" : "Destination"} */}
                    Destination
                  </th>
                  {/* {isWestcoast === "true" ? (
                    <th className="tdwidth SiteNumberHead">
                      <Icon.Truck /> Site Number
                    </th>
                  ) : (
                    ""
                  )} */}
                  <th className="tdwidth EtaHead">
                    <Icon.Clock /> ETA
                  </th>
                  <th className="CarrierHead">
                    <Icon.Truck /> Carrier
                  </th>
                  <th className="SiteNameHead">
                    <Icon.GeoAlt /> Site Name
                  </th>
                  {isWestcoast === "true" ? (
                    <th className="ArcoHead">
                      <Icon.GeoAlt /> Arco#
                    </th>
                  ) : (
                    ""
                  )}
                  <th className="tdwidthorder OrderHead">
                    {" "}
                    <Icon.CardList /> Order
                  </th>
                  <th className="tdwidthstatus- StatusHead">
                    <Icon.Circle /> Status
                  </th>
                  <th className="tdwidth UpdatedHead">
                    <Icon.ClockHistory /> Updated
                  </th>
                  <th></th>
                </tr>
              </thead>

              <tbody className="bgtbodycolor">
                {post ? renderOrders() : null}
              </tbody>
            </table>
          </div>
          <div class="desktop">
            <div className="rowCenter paddingside">
              <div className="row">
                {!showPrev ? (
                  <div className="col-md-1">
                    <button
                      class="btn btn-success btn-sm"
                      onClick={() => submitPrevPage()}
                      disabled={showPrev}
                    >
                      prev
                    </button>
                  </div>
                ) : (
                  <div className="col-md-1"></div>
                )}
                <div className="col-md-10 clrGreen">
                  {dataCount} / {totalDataCount}
                </div>
                {!showNext ? (
                  <div className="col-md-1">
                    <button
                      class="btn btn-success btn-sm"
                      onClick={() => submitNextPage()}
                      disabled={showNext}
                    >
                      Next
                    </button>
                  </div>
                ) : (
                  <div className="col-md-1"></div>
                )}
              </div>
            </div>
          </div>
          <div className="row mobile">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Type at least 4 chars to Search"
                minLength={3}
                id="txtsearchm"
                onChange={handletextChange}
              />
            </div>
          </div>
          <div className="divOrdersTablem mobile">
            {isLoading && (
              <div className="loader">
                <Spinner color="white" />
              </div>
            )}
            {post ? renderOrdersformobile() : null}
          </div>
          <div className="mobile">
            <div className="rowCenter paddingside">
              <div className="row">
                <div className="col-md-10 clrGreen card-body">
                  {!showPrev ? (
                    <button
                      class="btn btn-success btn-sm floatleft"
                      onClick={() => submitPrevPage()}
                      disabled={showPrev}
                    >
                      prev
                    </button>
                  ) : (
                    <div className="col"></div>
                  )}
                  {dataCount} / {totalDataCount}
                  {!showNext ? (
                    <button
                      class="btn btn-success btn-sm floatright"
                      onClick={() => submitNextPage()}
                      disabled={showNext}
                    >
                      Next
                    </button>
                  ) : (
                    <div className="col"></div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Modal
            onClose={() => {
              setShow(false);
              bindSelectedItems();
            }}
            show={show}
          ></Modal>
          <HistoryModal
            onClose={() => {
              setShowmodal(false);
              bindSelectedItems();
            }}
            modalshow={modalshow}
          ></HistoryModal>
          <ColumnFilter
            onClose={() => {
              setShowColumnFilter(false);
              // getcheckedData();
            }}
            ColumnFiltershow={columnFiltershow}
            isWestcoast={isWestcoast}
          ></ColumnFilter>
        </div>
      ) : (
        <div className="loader">
          <Spinner color="white" />
        </div>
      )}
    </div>
  );
};
export default OrderTable;
