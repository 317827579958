
const Footer = props =>{
   return(
    <footer className="footer border-top">
    <div className="text-muted row">
        <div className="row desktop">
           <div  className="col-md-1">
           <p className="changecolor">bp p.l.c </p>
           </div>
        <div className="col-md-8">
        <p className="lblsetmargin">Copyright &copy;1996-{new Date().getFullYear()} &nbsp;&nbsp;&nbsp;&nbsp;Terms & Conditions &nbsp;&nbsp;&nbsp;&nbsp; Privacy Policy</p>
        </div>
        </div>
        <div className="row mobile">
           <div  className="col">
           <p className="changecolor">bp p.l.c &nbsp;&nbsp;&nbsp;&nbsp; Copyright &copy;1996-{new Date().getFullYear()} &nbsp;&nbsp;&nbsp;&nbsp;Terms & Conditions &nbsp;&nbsp;&nbsp;&nbsp; Privacy Policy</p>
           </div>
        </div>
     </div>
  </footer>
   );
};

export default Footer;